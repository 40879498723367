import React, { useEffect, useState } from 'react';
import useApiCaller from '../hooks/useApiCaller';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';

import HoncAreaManagers from '../components/mini/HoncAreaManagers';
import HoncAreaMangerslist from '../components/modals/HoncAreaMangerslist';
import { roles } from '../../src/constants/roles';
import _ from 'lodash';
import AddAreaManager from '../components/AddAreaManager';

const AreaManager = (props) => {
  const { fetchData } = useApiCaller();
  const [users, setUsers] = useState([]);
  const [searchText,setSearchText]=useState();
  const [managerTypes, setManagerTypes] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [userClientIds, setUserClientIds] = useState([]);
  const [managers, setManagers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [finalManagers, setFinalManagers] = useState([]);
  const [remainingClientIds, setRemainingClientIds] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const roleFullForms = roles?.reduce((obj, item) => {
    return {
        ...obj,
        [item.value]: item?.label,
        [item.label]: item?.value,
        priority: item?.priority
    }
}, {})

  const [selectedManagerToUpdate, setSelectedManagerToUpdate] = useState({});
  

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log("Selected Option:", selectedOption);
  };

  const fetchAreaManagers = async () => {
    try {
      const reqBody = {
        role: 'HNC-RGN'
      };

      const Honcareamanagers = await fetchData('POST', 'v1/user/all', reqBody);

      if (Honcareamanagers?.success) {
        setUsers(Honcareamanagers?.data?.users);
      } else {
        Swal.fire('Warning!', 'Something went wrong.', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire('Error!', 'Failed to fetch data from the API.', 'error');
    }
  };

  useEffect(() => {
    fetchAreaManagers();
  }, []); 

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);

  };
  useEffect(()=>
  {
   
  test()
   
  },[])
  let test=async()=>{
    try{
    const fetchUsersList = await fetchData("POST", "v1/user/all")
    if (fetchUsersList?.success) 
    {
      console.log("sneha",fetchUsersList)
    }
    else{
      console.log("error")
    }
    
    }
    catch (error) {
      console.error('Error:', error);
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqBody = {
        role: 'HNC-RGN'
      };

        const response = await fetch("POST", "v1/user/all",reqBody
       
        );
        if (response.ok) {
          const data = await response.json();
          setUsersList(data); // Update the state with fetched data
        } else {
          throw new Error('Failed to fetch');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error scenarios
      }
    };

    fetchData(); // Invoke fetchData when the component mounts
  }, []); // Empty dependenc  
  useEffect(()=>
  {
      // fetchresponse()
 
  },[])
   
  // const fetchresponse = async () => {
  //   try {

  //     const fetchClientsList = await fetchData("POST", "v1/client/all");
  
  //     if (fetchClientsList?.success) {
  //       const clients = fetchClientsList?.data?.clients;
  //     console.log("snehitha8",clients.length)
       
  //       const fetchUsersList = await fetchData("POST", "v1/user/all");
  
  //       if (fetchUsersList?.success) {
  //         const users = fetchUsersList?.data?.users;
  //         const userClientIds = users.map((user) => user.clientIds);
  //         setUserClientIds(userClientIds);
  //         console.log("User Client IDs:", userClientIds.length);  
  //         const userId=fetchUsersList?.data?.users?.map((user)=>user._id)
  //         console.log("snehitha",userId)
  
  //         const fetchedOptions = clients
  //          .filter((client) => {
        
  //             return !userClientIds.some((ids) => ids.includes(client._id ));
  //           })
  //           .map((client) => ({
  //             label: client.name,
  //             value: client._id,
  //           }));
            
          
  
  //         console.log("Filtered Options:", fetchedOptions); 
  
  //         setOptions(fetchedOptions);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
 
  const fetchResponse = async () => {
    try {
      const fetchClientsList = await fetchData("POST", "v1/client/all");
  
      if (fetchClientsList?.success) {
        const clients = fetchClientsList?.data?.clients;
  
        const fetchUsersList = await fetchData("POST", "v1/user/all");
  
        if (fetchUsersList?.success) {
          const users = fetchUsersList?.data?.users;
  
          const remainingClientIdsPerUser = {};
          
  
          const filteredUsersHNCRGN = users.filter(user => user.role === 'HNC-RGN'); // Filter users by role
  
          const uniqueFirstNames = new Set(filteredUsersHNCRGN.map(user => user.name.split(' ')[0])); // Filtered uniqueFirstNames
  
          uniqueFirstNames.forEach(async firstName => {
            const filteredUsers = filteredUsersHNCRGN.filter(user => user.name.startsWith(firstName));
  
            const userClientIds = filteredUsers.flatMap(user => user.clientIds);
  
            const remainingClientIds = clients.filter(client =>
              !userClientIds.includes(client._id)
            ).map(client => ({
              label: client.name,
              value: client._id,
            }));
  
            remainingClientIdsPerUser[firstName] = remainingClientIds;
  
            console.log(`Remaining Client IDs for ${firstName}:`, remainingClientIds);
          });
  
         
          setUsers(filteredUsersHNCRGN.map(user => ({
            ...user,
            remainingClientIds: remainingClientIdsPerUser[user.name.split(' ')[0]] || [], // Pass remainingClientIds for each user
          })));
          setRemainingClientIds(remainingClientIdsPerUser);
        }
      }
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    fetchResponse();
  }, []);
  
  // const handleAssignClients = async (managerId) => {
  //   if (selectedOption && selectedOption.length > 0) {
  //     const managerclientIds = selectedOption.map((item) => item.value);
  //     let remainingClientIds= selectedManagerToUpdate.remainingClientIds.map(item => item.value)
  //     const clientdetails = [managerclientIds ]
  //     const reqBody = {
  //       clientIds: clientdetails,
  //   // Append remainingClientIds to the request body
  //     };
  
  //     try {
  //       const Clients = await fetchData("POST", `v1/user/update/${managerId}`, reqBody);
  //       if (Clients?.success) {
  //         // let filteredOptions = options.filter(item => !(Clients?.data?.clients?.map(initem => initem?._id)?.includes(item?.value)));
  //         const filteredOptions = options.filter((item) => !managerclientIds.includes(item.value));
  //         setOptions(filteredOptions);
  //         setSelectedOption([]);
  //         swal.fire(
  //           'Your response has been submitted successfully',
  //           '',
  //           'success'
  //         );
  //       } else {
  //         alert("Something went wrong.");
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }
  // };
  const handleAssignClients = async (managerId) => {
    if (selectedOption && selectedOption.length > 0) {
      const managerClientIds = selectedOption.map((item) => item.value);
      
     
      const existingClientIds = selectedManagerToUpdate.clientIds || [];
      
      
      const mergedClientIds = [...existingClientIds, ...managerClientIds];
      
      const reqBody = {
        clientIds: mergedClientIds, 
      };
  
      try {
        const Clients = await fetchData("POST", `v1/user/update/${managerId}`, reqBody);
        if (Clients?.success) {
          const updatedOptionValues = selectedOption.map((item) => item.value);
          const filteredOptions = options.filter((option) => !updatedOptionValues.includes(option.value));
          
          setOptions(filteredOptions);
          setSelectedOption([]);
          Swal.fire("Success","Your response has submitted succesfully","success")
         
       
          fetchAreaManagers();
          fetchResponse();
        
      
        } else {
          alert("Something went wrong.");
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  
  useEffect(() => {
    handleAssignClients();
    
  }, []);

  const handleSelectManagerToUpdate = (managerId, mode) => {
    setSelectedManagerToUpdate(users?.filter(item => item?._id === managerId)[0]);
  
    if (mode === "assignclient") {
        document.getElementById('assignclientbtn').click();
    }




 }

 useEffect(() => {
  getAllManagers();
}, [])

const getAllManagers = async () => {
  let reqBody = {
    role: 'HNC-RGN'
  };

  let managersList = await fetchData('POST', 'v1/user/all', reqBody);
  if (managersList?.success) {
      let localManagers = managersList?.data?.users;
      setManagers(localManagers);
      setFinalManagers(localManagers);
      let localManagerTypes = _.uniqBy(localManagers, item => item?.role)?.map(item => {
        if (item.role === 'HNC-RGN') {
          return {
              label: 'HNC-RGN',
              isSelected: false,
              priority: roles?.filter(innerItem => innerItem?.label == 'HNC-RGN')[0]?.priority
          };
      }
      return null; // Return null for roles other than 'HNC-RGN'
  }).filter(Boolean);
      localManagerTypes.unshift(
          {
              label: "All",
              isSelected: true,
              priority: 0
          }
      )
      setManagerTypes(localManagerTypes);
  }
  else {
      swal.fire(
          'Something went wrong',
          '',
          'error'
      )
  }
}





  console.log("AreaManagers - clientId:", props?.clientId);
console.log("snehitha",options)

  return (
    <div>
      {users.length > 0 ? (
      <div>
        <div className='d-flex'>
        <div className='col-lg-9'>
        <h4>Honc Area Managers{' '}({users?.length})</h4>
        </div>
        <div className='col-lg-3'>
        <div className=" text-end d-flex justify-content-md-end justify-content-center me-4" style={{ gap: "10px" }}>

                        <a href="javascript:void(0)" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addAreaManagerModal">
                          Add Manager
                        </a>
                      
                  
                </div>
        </div>
        </div>
     
        {/* <div className="col-md-4 col-xl-3 ">
                    <div className="position-relative">
                     
                          <input
        type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Managers..."
        value={searchText}
        onChange={handleSearchChange}
      />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                    </div>
                </div> */}
               <div className="row mt-4">
                
          {users?.map((user, index) => (
                   <div className="col-md-3 col-lg-3 d-flex " key={user?._id}>
            
            <HoncAreaManagers
            name={user?.name}
            email={user?.email}
            password={user?.password}
            hubs={user?.hubIds}
            managerId={user?._id}
            client={user?.clientIds}
            clientId={props?.clientId}
            userClientIds={userClientIds}
            remainingClientIds={user?.remainingClientIds || []}
          handleSelectManagerToUpdate={(managerId,mode) => handleSelectManagerToUpdate(managerId,mode)}
        //  Clientslist={Clientslist}
        />
            </div>
          ))}
        
          </div>
        </div>
      ) : (
        'No managers found'
      )}
         {showModal ? (
   <HoncAreaMangerslist
  // clientId={props?.clientId}
  // fetchAreaManagers={fetchAreaManagers}
  managerId={selectedManagerToUpdate?._id}
  managerName = {selectedManagerToUpdate?.name}
  // options={options}
  remainingClientIds={selectedManagerToUpdate?.remainingClientIds}
  selectedOption={selectedOption}
  onChange={handleChange}
  handleAssignClients={handleAssignClients} 
  

 
 

  // Clientslist={Clientslist}
  // options={clientOptions} // Pass the fetchedOptions as a prop
  // selectedOption={selectedOption}
/>):null}

         <button
                id={`assignclientbtn`}
                className='d-none'
                data-bs-toggle="modal"
                data-bs-target={`#assignClientModal`}>
            </button>
            <AddAreaManager
                    getAllManagers={getAllManagers}
                    fetchAreaManagers={fetchAreaManagers}
                    fetchResponse={fetchResponse}
                    // clientId={props?.clientId}
                    // hubId={props?.hubId}
                    // cityId={props?.cityId}
                    // isFromHub={props?.isFromHub}
                />
    </div>
  );
};

export default AreaManager;
