export const carBodyTypes = [
    {
        label: 'Hatchback',
        value: 'hatchback'
    },
    {
        label: 'Sedan',
        value: 'sedan'
    },
    {
        label: 'SUV',
        value: 'suv'
    },
    {
        label: 'Luxary',
        value: 'luxary'
    },
    {
        label: 'Premium',
        value: 'premium'
    },
    {
        label: 'Ultra Luxary',
        value: 'ultra_luxary'
    },
    {
        label: 'Super Luxary',
        value: 'super_luxary'
    }
]