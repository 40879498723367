import React, { useEffect, useState } from 'react'

export default function     JobFilters(props) {
    return (
        <div className="modal fade" id="filterJobsModal" tabIndex={-1} role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title">Filters</h5>
                        <button type="button" className="btn-close" id="job-filters-close-btn" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <h5>Date Range</h5>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <label>From date</label>
                                <input type='date' className='form-control' value={props?.filters?.fromDate || ''} onChange={(e) => {
                                    props?.setFilters({
                                        ...props?.filters,
                                        fromDate: e.target.value
                                    })
                                }} />
                            </div>
                            <div className='col-md-6'>
                                <label>To date</label>
                                <input type='date' className='form-control' value={props?.filters?.toDate || ''} onChange={(e) => {
                                    props?.setFilters({
                                        ...props?.filters,
                                        toDate: e.target.value
                                    })
                                }} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-primary' onClick={() => {
                                props?.handleApplyFilters();
                                document.getElementById('job-filters-close-btn').click();
                            }}>Apply Filters</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
