import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApiCaller from '../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setClientPageNum, setClientPageSize, setClientSearchText } from '../redux/nonPersistActions';
import Table from '../components/Table';
import _, { update } from 'lodash';
import PageBreadCrumb from '../components/PageBreadCrumb';
import AddClient from '../components/modals/AddClient';
import "../styles/table.css"


const Clients = () => {
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);

  const { fetchData } = useApiCaller();
  const { clientPageNum, clientPageSize, clientSearchText } = useSelector(state => state?.nonPersistReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllClients = async (pgno, pgSize) => {
    let reqBody = {
      "pageNum": pgno,
      "pageSize": pgSize,
      "search": clientSearchText
    }
    let allClientsResp = await fetchData('POST', 'v1/client/all', reqBody);
    if (allClientsResp?.success) {
      setClients(allClientsResp?.data?.clients);
      setTotalClients(allClientsResp?.data?.totalCount);
    }
    else {
      swal.fire(
        'Something went wrong',
        '',
        'error'
      )
    }
  }


  const delayedQuery = useCallback(_.debounce(() => fetchAllClients(1, clientPageSize), 1000), []);

  useEffect(() => {
    if (clientSearchText != "") {
    
      // delayedQuery();
      fetchAllClients(1, clientPageSize);
      dispatch(setClientPageNum(1));
    }
    else {
      fetchAllClients(clientPageNum, clientPageSize);
    }
  }, [clientSearchText])

  const handlePageClick = (data) => {
    let locCurrentPage = data.selected + 1;
    dispatch(setClientPageNum(locCurrentPage));
    fetchAllClients(locCurrentPage, clientPageSize);
  }
  const calculateSerialNumber = (index) => {

    const offset = (clientPageNum - 1) * clientPageSize;

    return offset + index + 1;
  };

  const tableheaders = [
    "S.NO", "Name", "Phone", "Email", "Actions"
  ]

  const handleChangePageSize = (pageSize) => {
    dispatch(setClientPageSize(pageSize));
    fetchAllClients(1, pageSize);
  }

  return (
    <div className="container-fluid">
      <PageBreadCrumb
        pageTitle='Clients'
        breadCrumbs={[
          {
            label:  'Clients',
            link: '#'
          }
        ]}
      />
      <div className="widget-content searchable-container list">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-xl-3">
              <form className="position-relative">
                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Clients..." value={clientSearchText} onChange={(e) => {
                  dispatch(setClientSearchText(e.target.value));
                }} />
                <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
              </form>
            </div>
            <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0" style={{ gap: "10px" }}>
              <a href="javascript:void(0)" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addClientModal">
                <i className="ti ti-users text-white me-1 fs-5" /> Add Client
              </a>
            </div>
          </div>
        </div>
        <Table
          headers={tableheaders}
          pageSize={clientPageSize}
          handleChangePageSize={handleChangePageSize}
          startCount={calculateSerialNumber(0)}
          endCount={totalClients < (clientPageNum * clientPageSize) ? totalClients : (clientPageNum * clientPageSize)}
          totalCount={totalClients}
          handlePageClick={handlePageClick}
          pageNum={clientPageNum}
          pageCount={(totalClients / clientPageSize)}
        >
          <tbody>
            {clients?.map((item, index) => (
              <tr className="search-items" key={item._id} >
                <td>{calculateSerialNumber(index)}</td>
                <td>
                  <div className="d-flex align-items-center">
                    {item?.logo ?
                      <img src={item?.logo} alt="avatar" class="rounded-circle image-container"
 />
                      :
                      <img src="../../dist/images/profile/user-1.jpg" alt="avatar" className="rounded-circle" width={35} />
                    }
                    <div className="ms-3">
                      <td className="user-name mb-0">
                        <p className='m-0 text-primary' style={{
                          cursor: 'pointer'
                        }} onClick={() => {
                          navigate(`/client-dashboard?id=${item?._id}`)
                        }}
                        >
                          {item.name}
                        </p>
                      </td>
                    </div>
                  </div>
                </td>
                <td className="usr-ph-no">{item.phone}</td>
                <td className="usr-email-addr">{item.email}</td>
                <td>
                  <button className='btn btn-primary' onClick={() => {
                    navigate(`/client-dashboard?id=${item?._id}`)
                  }}>
                    <i className='ti ti-eye' style={{ fontWeight: 'bold' }} /> View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <AddClient
          fetchAllClients={fetchAllClients}
        />
      </div>
    </div>
  );
}

export default Clients