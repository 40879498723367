import React from 'react'
import { useForm, useWatch } from 'react-hook-form';
import useApiCaller from '../../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useEffect, useState } from 'react';



const ChangePassword = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  }

  const { fetchData } = useApiCaller();

  const handleOnChnagePassword = async (data) => {
    const confirmationResult = await swal.fire({
        title: 'Are you ready to update?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });
      if (confirmationResult.isConfirmed) {
        let reqBody = {
        "password":data.password
        };
    
    setLoading(true);

      try {
        let ChangePasswordDetails = await fetchData('POST', `v1/user/update/${props?.managerId}`, reqBody);

        if (ChangePasswordDetails?.success) {
          document.getElementById(`clsCPtn${props?.managerId}`).click();
          props?.getAllManagers();
          swal.fire(
            'Success', "Your password has been changed successfully", 'success'
          );
        } else {
          swal.fire(
            'Something went wrong',
            '',
            'error'
          );
        }
      } catch (error) {
        console.error("Error:", error);
        swal.fire(
          'Error occurred',
          'Please try again later.',
          'error'
        );
      } finally {
        setLoading(false);
      }
    }
    else {
      setLoading(false); // Set loading back to false if the user cancels
    }

  }
  // useEffect(() => {
  //   setValue('password', props.password || '');
   
  // }, [props.password]);
  console.log(ChangePassword)
  return (
    <div>
      <div
        className="modal fade"
        id={`ChangePassword`}
        tabIndex="-1"
        aria-labelledby="ChangePassword"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5>ChangePassword</h5>
              <button type="button" id={`clsCPtn${props?.managerId}`} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
              {loading ? (
                <h5><b>Loading, please wait...</b></h5>
              ) : (
                <form id="addContactModalTitle" onSubmit={handleSubmit(handleOnChnagePassword)}>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 contact-name">
                        <label> Password<span className='text-danger'>*</span></label>
                        <div className="input-group password-input">
                          <input
                            type={passwordVisible ? 'text' : 'password'} // Step 3
                            {...register('password', { required: true, minLength: 8 })}
                            className="form-control"
                            value={watch('password')}
                            placeholder="Enter new password"
                          />
                          <span className="input-group-text" onClick={togglePasswordVisibility}>
                            <i className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`} />
                          </span>
                        </div>
                        {errors.password?.type === 'required' && (
                          <p><small className='text-danger'>Please enter a password.</small></p>
                        )}
                        {errors.password?.type === 'minLength' && (
                          <p><small className='text-danger'>Password must be at least 8 characters long.</small></p>
                        )}
                      </div>
                    </div>
                  </div>

                  <input type="submit" className="btn btn-success px-4" />


                </form>
              )}


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword