const HNC_sidebar = [
    // {
    //     label: "Dashboard",
    //     key: 'dashboard',
    //     icon: 'ti ti-chart-donut-3',
    //     pathname: '/dashboard'

    // },
    {
        label: "Stats",
        key: 'Stats',
        icon: 'ti ti-chart-pie-filled',
        pathname: '/stats',
      
    },
    {
        label: "Clients",
        key: 'clients',
        icon: 'ti ti-users',
        pathname: '/clients',
        subRoutes: ['/client-dashboard', '/hub-dashboard']
    },
    {
        label: "Area Managers",
        key: 'AreaManger',
       icon:'ti ti-user',
        pathname: '/AreaManager',
      
       
    },
  
    // {
    //     label: "Honc Area Managers",
    //     key: 'honc-area-managers',
    //     icon: 'ti ti-user',
    //     pathname: '/manage-honc-area-managers',
    // },
    // {
    //     label: "Profile",
    //     key: 'profile',
    //     icon: 'ti ti-user-circle',
    //     pathname: '/profile',
    // }
]
const HNC_RGN_sidebar = [
    // {
    //     label: "Dashboard",
    //     key: 'dashboard',
    //     icon: 'ti ti-chart-donut-3',
    //     pathname: '/dashboard'

    // },
    {
        label: "Stats",
        key: 'Stats',
        icon: 'ti ti-chart-pie-filled',
        pathname: '/stats',
      
    },
    {
        label: "Clients",
        key: 'clients',
        icon: 'ti ti-users',
        pathname: '/clients',
        subRoutes: ['/client-dashboard', '/hub-dashboard']
    },
   
   
    // {
    //     label: "Profile",
    //     key: 'profile',
    //     icon: 'ti ti-user-circle',
    //     pathname: '/profile',
    // }
]
const CLT_sidebar = [
    // {
    //     label: "Dashboard",
    //     key: 'dashboard',
    //     icon: 'ti ti-chart-donut-3',
    //     pathname: '/dashboard'

    // },
    {
        label: "Stats",
        key: 'Stats',
        icon: 'ti ti-chart-pie-filled',
        pathname: '/stats',
      
    },
    {
        label: "Dashboard",
        key: 'client-dashboard',
        icon: 'ti ti-chart-donut-3',
        pathname: '/client-dashboard',
        subRoutes: ['/hub-dashboard']
    },
   
    // {
    //     label: "Profile",
    //     key: 'profile',
    //     icon: 'ti ti-user-circle',
    //     pathname: '/profile',
    // }
]
const CLT_RGN_sidebar = [
    // {
    //     label: "Dashboard",
    //     key: 'dashboard',
    //     icon: 'ti ti-chart-donut-3',
    //     pathname: '/dashboard'

    // },
    {
        label: "Stats",
        key: 'Stats',
        icon: 'ti ti-chart-pie-filled',
        pathname: '/stats',
      
    },
    {
        label: "Dashboard",
        key: 'client-dashboard',
        icon: 'ti ti-chart-donut-3',
        pathname: '/client-dashboard',
        subRoutes: ['/hub-dashboard']
    },
    
    // {
    //     label: "Profile",
    //     key: 'profile',
    //     icon: 'ti ti-user-circle',
    //     pathname: '/profile',
    // }
]
const CLT_HUB_sidebar = [
 
    {
        label: "Stats",
        key: 'Stats',
        icon: 'ti ti-chart-pie-filled',
        pathname: '/stats',
      
    },
    {
        label: "Dashboard",
        key: 'hub-dashboard',
        icon: 'ti ti-chart-donut-3',
        pathname: '/hub-dashboard',
    },
   
    {
        label:"Partner",
        key:"Partner",
        icon:'ti ti-user',
        pathname:'/PartnersList'
    },
    {
        label:"Vehicles",
        key:"Vehicles",
        icon:'ti ti-car',
        pathname:'/VehiclesList'
    }
   
]


export {
    HNC_sidebar,
    HNC_RGN_sidebar,
    CLT_sidebar,
    CLT_RGN_sidebar,
    CLT_HUB_sidebar
}

// child Example

// children: [
//     {
//         label: "Jobs",
//         key: 'jobs',
//         icon: 'ti ti-chart-donut-3',
//         pathname: '/jobs/details',
//     }
// ]