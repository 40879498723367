import { actionTypes } from "../../constants/reduxConstants"

const initialState = {
    name: '',
    user: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            }
        case actionTypes.RESET:
            return {
                ...initialState,
            }
        default:
            return state
    }
}