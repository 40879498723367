import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert2';
import { setClientPageNum } from '../../redux/nonPersistActions';
import { useForm } from 'react-hook-form';
import useApiCaller from '../../hooks/useApiCaller';
import useUploadImage from '../../hooks/useUploadImage';

export default function AddPartners(props) {
    const { uploadImage } = useUploadImage();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();

    const { fetchData } = useApiCaller();

    const handleUploadImage = async (e, fieldName) => {
        let imageResponse = await uploadImage(e);
        if (imageResponse) {
            setValue(fieldName, imageResponse.link);
        }
    }

    const handleOnCreatePartner = async (data) => {
        let reqObj = {
            name: data?.name,
            phone: data?.phone,
            aadhar_num: data?.aadhar_num,
            hubId: props?.hubId,
            clientId: props?.clientId,
            profileImage: data?.profileImage || "",
            aadhar_front_image: data?.aadhar_front_image || "",
            aadhar_back_image: data?.aadhar_back_image || ""
        };
        let createdResponse = await fetchData('POST', 'v1/partner/create', reqObj);
        if (createdResponse?.success) {
            document.getElementById('closeAddPartnerModalBtn').click();
            reset();
            props?.fetchAllPartners();
            swal.fire(
                'Successfully Created',
                'A new partner has been created successfully.',
                'success'
            )
        }
        else {
            swal.fire(
                createdResponse?.message,
                '',
                'error'
            )
        }
    };

    return (
        <div className="modal fade" id="addPartnerModal" tabIndex={-1} role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title">Create New Partner</h5>
                        <button type="button" id="closeAddPartnerModalBtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="add-contact-box">

                            <div className="add-contact-content" >
                                <form id="addContactModalTitle" onSubmit={handleSubmit(handleOnCreatePartner)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3 contact-name">
                                                <label>Name<span className='text-danger'>*</span></label>
                                                <input type="text" {...register('name', { required: true })} className="form-control" placeholder="enter name" />
                                                {errors.name && <p><small className='text-danger'>Please enter name.</small></p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3 contact-email">
                                                <label>Phone<span className='text-danger'>*</span></label>
                                                <input type="tel" {...register('phone', { pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/i, required: true })} maxlength="10"
                                                    className="form-control" placeholder="enter phone number" />
                                                {errors.phone && <p><small className='text-danger'>Please enter a valid mobile number.</small></p>}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3 contact-phone">
                                                <label>Profile Image</label>
                                                <input type='file' className='form-control' onChange={(e) => {
                                                    handleUploadImage(e, 'profileImage')
                                                }} />
                                            </div>
                                        </div>
                                        <div className='mb-3' style={{
                                            borderBottom: '0.6px solid #BCBCBC'
                                        }} />
                                        <h5>Aadhar details</h5>
                                        <div className="col-md-12">
                                            <div className="mb-3 contact-location">
                                                <label>Aadhar Number</label>
                                                <input type="tel"
                                                    {...register('aadhar_num', { pattern: /^\d{12}$/i })}
                                                    className="form-control" placeholder=" enter aadhar number" maxlength="12" onChange={() => { }} />
                                                {errors.aadhar_num && <p><small className='text-danger'>Please enter valid aadhar no.</small></p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3 contact-phone">
                                                <label>Aadhar Front</label>
                                                <input type='file' className='form-control' onChange={(e) => {
                                                    handleUploadImage(e, 'aadhar_front_image')
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3 contact-phone">
                                                <label>Aadhar Back</label>
                                                <input type='file' className='form-control' onChange={(e) => {
                                                    handleUploadImage(e, 'aadhar_back_image')
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <input type="submit" className="btn btn-success rounded-pill px-4" />
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    )
}
