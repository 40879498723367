import { type } from "@testing-library/user-event/dist/type"
import { actionTypes } from "../constants/nonPersistReduxConstants"

export const simpleAction = (name) => dispatch => {
    dispatch({
        type: actionTypes.SIMPLE_ACTION,
        payload: name
    })
}

export const setClientPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CLIENT_PAGE_SIZE,
        pageSize
    })
}

export const setClientPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CLIENT_PAGE_NUM,
        pageNum
    })
}

export const setClientSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CLIENT_SEARCH_TEXT,
        searchText
    })
}

export const setVehiclePageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_VEHICLE_PAGE_SIZE,
        pageSize
    })
}

export const setVehiclePageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_VEHICLE_PAGE_NUM,
        pageNum
    })
}

export const setVehicleSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_VEHICLE_SEARCH_TEXT,
        searchText
    })
}

export const setCompletedJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_COMPLETEDJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setCompletedJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_COMPLETEDJOBS_PAGE_NUM,
        pageNum
    })
}

export const setCompletedJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes. SET_COMPLETEDJOBS_SEARCH_TEXT,
        searchText
    })
}

export const setNotAttemptedJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_NOT_ATTEMPTEDJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setNotAttemptedJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_NOT_ATTEMPTEDJOBS_PAGE_NUM,
        pageNum
    })
}

export const setNotAttemptedJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.  SET_NOT_ATTEMPTEDJOBS_PAGE_TEXT,
        searchText
    })
}


export const setIncompleteJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes. SET_INCOMPLETEJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setIncompleteJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes. SET_INCOMPLETEJOBS_PAGE_NUM,
        pageNum
    })
}

export const setIncompleteJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_INCOMPLETEJOBS_PAGE_TEXT,
        searchText
    })
}


export const setApprovalPendingJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_APPROVALPENDING_PAGE_SIZE,
        pageSize
    })
}

export const setApprovalPendingJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_APPROVALPENDING_PAGE_NUM,
        pageNum
    })
}

export const setApprovalPendingJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_APPROVALPENDING_PAGE_TEXT,
        searchText
    })
}


export const setAddonCompletedJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_COMPLETEDJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setAddonCompletedJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_COMPLETEDJOBS_PAGE_NUM,
        pageNum
    })
}

export const setAddonCompletedJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_COMPLETEDJOBS_SEARCH_TEXT,
        searchText
    })
}

export const setAddonNotAttemptedJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setAddonNotAttemptedJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_NUM,
        pageNum
    })
}

export const setAddonNotAttemptedJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_SEARCH_TEXT,
        searchText
    })
}


export const setAddonInCompletedJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_INCOMPLETEDJOBS_PAGE_SIZE,
        pageSize
    })
}

export const setAddonInCompletedJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_INCOMPLETEDJOBS_PAGE_NUM,
        pageNum
    })
}

export const setAddonInCompletedJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_INCOMPLETEDJOBS_SEARCH_TEXT,
        searchText
    })
}

export const setAddonApprovalPendingJobsPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_APPROVALPENDING_PAGE_SIZE,
        pageSize
    })
}

export const setAddonApprovalPendingJobsPageNum = (pageNum) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_APPROVALPENDING_PAGE_NUM,
        pageNum
    })
}

export const setAddonApprovalPendingJobsSearchText = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ADDON_APPROVALPENDING_SEARCH_TEXT,
        searchText
    })
}



export const resetNonPersistStore = () => dispatch => {
    dispatch({
        type: actionTypes.RESET,
    })
}