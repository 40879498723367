import React, { useEffect, useState } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'
import { CLT_HUB_sidebar, CLT_RGN_sidebar, CLT_sidebar, HNC_RGN_sidebar, HNC_sidebar } from '../constants/sidebar';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { resetPersistStore } from '../redux/actions';
import { resetNonPersistStore } from '../redux/nonPersistActions';
import "../styles/toggle.css";
import { roles } from '../constants/roles';





export default function Sidebar() {
    const [activeMenu, setActiveMenu] = useState([]);



    let location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state?.reducer?.user);

    var setsidebartype = function () {
        var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width;
        var mainWrapper = document.getElementById("main-wrapper");

        if (width < 1170) {
            mainWrapper.setAttribute("data-sidebartype", "mini-sidebar");
            mainWrapper.classList.add("mini-sidebar");
        } else {
            mainWrapper.setAttribute("data-sidebartype", "full");
            mainWrapper.classList.remove("mini-sidebar");
        }
    };

    useEffect(() => {
        window.addEventListener('resize', setsidebartype);
        Array.from(document.getElementsByClassName("sidebartoggler")).forEach((element) => {
            element.addEventListener("click", () => {
                const mainWrapper = document.getElementById("main-wrapper");

                mainWrapper.classList.toggle("mini-sidebar");
                if (mainWrapper.classList.contains("mini-sidebar")) {
                    Array.from(document.getElementsByClassName("sidebartoggler")).forEach((element) => {
                        element.checked = true;
                    });
                    mainWrapper.setAttribute("data-sidebartype", "mini-sidebar");
                }
                else {
                    Array.from(document.getElementsByClassName("sidebartoggler")).forEach((element) => {
                        element.checked = false;
                    });
                    mainWrapper.setAttribute("data-sidebartype", "full");
                }
                document.querySelectorAll(".sidebartoggler i").forEach((icon) => {
                    icon.classList.toggle("text-primary");
                });
            }, []);
        })
        window.addEventListener("scroll", function () {
            var appHeader = document.querySelector(".app-header");
            if (window.pageYOffset >= 60) {
                appHeader.classList.add("fixed-header");
            } else {
                appHeader.classList.remove("fixed-header");
            }
        });
        return () => {
            window.removeEventListener('resize', () => { });
            Array.from(document.getElementsByClassName("sidebartoggler")).forEach((element) => {
                element.removeEventListener("click", () => { });
            })
            window.removeEventListener('scroll', () => { });
        };
    }, [])

    useEffect(() => {
        let sidebar = []
        if (user?.role == "HNC") {
            sidebar = HNC_sidebar;
        }
        else if (user?.role == "HNC-RGN") {
            sidebar = HNC_RGN_sidebar;
        }
        else if (user?.role == "CLT") {
            sidebar = CLT_sidebar?.map(item => {
                if (item?.key == "client-dashboard") {
                    // const originalUrl = "https://www.example.com/page?param1=value1&param2=value2&param3=value3";

                    // // Find the index of the "?" symbol
                    // const questionMarkIndex = originalUrl.indexOf("?");

                    // if (questionMarkIndex !== -1) {
                    //     // Extract the substring before the "?" symbol
                    //     const newUrl = originalUrl.substring(0, questionMarkIndex);
                    //     console.log("New URL:", newUrl);
                    // } else {
                    //     console.log("No query parameters found in the URL");
                    // }
                    item.pathname = item?.pathname + "?id=" + user?.clientIds[0]
                }
                return item;
            });
        }
        else if (user?.role == "CLT-RGN") {
            sidebar = CLT_RGN_sidebar?.map(item => {
                if (item?.key == "client-dashboard") {
                    item.pathname = item?.pathname + "?id=" + user?.clientIds[0]
                }
                return item;
            });
        }
        else if (user?.role == "CLT-HUB") {
            sidebar = CLT_HUB_sidebar?.map(item => {
                if (item?.key == "hub-dashboard") {
                    item.pathname = item?.pathname + "?id=" + user?.hubIds[0]
                }
               
                return item;
               
              
            });;
        }
        setActiveMenu(sidebar?.map(item => {
            if (item?.children?.length > 0) {
                item?.children?.map(innerItem => {
                    if (location?.pathname === innerItem?.pathname) {
                        item.active = true;
                    }
                })
            }
            return item;
        }));
    }, [])

    const handleSelectMenuOption = (key) => {
        setActiveMenu(activeMenu?.map(item => {
            if (item?.key == key) {
                item.active = true;
            }
            else {
                item.active = false;
            }
            return item;
        }))
    }

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure, you want to logout.',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                'Confirm',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
                'Cancel',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(resetPersistStore());
                dispatch(resetNonPersistStore());
                navigate('/');
            }
        })
    }

    return (
        <div>
            {/*  Body Wrapper */}
            <div className="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
                {/* Sidebar Start */}
                <aside className="left-sidebar">

                    {/* Sidebar scroll*/}
                    <div>
                        <div className="brand-logo d-flex align-items-center justify-content-between" >
                            <div style={{


                            }}>
                                {/* {document.getElementById('main-wrapper').classList?.filter(item => item == "mini-sidebar").length>0?
                                } */}
                                <img src="./images/honc_logo.png" className="dark-logo" alt="" />



                            </div>

                            <div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                                <i className="ti ti-x fs-8" />
                            </div>
                        </div>
                        <div className='mx-3 '>
                            <li className="sidebar-item" style={{ listStyle: "none" }}>
                                <div className="card w-100  " aria-expanded="false">
                                    <div className="p-4 d-flex align-items-stretch ">
                                        <div className="row">

                                            <div className="col-4 col-md-3 d-flex align-items-center justify-content-center">
                                                <img src="https://cdn-icons-png.flaticon.com/512/666/666201.png" style={{ width: "3vw", borderRadius: "50%" }} className='user-profile' />
                                            </div>


                                            <div className="col-8 col-md-9 d-flex align-items-center" style={{ justifyContent: "center" }}>
                                                <div>
                                                    {/* Only render name and role if userSectionVisible is true */}
                                                    <div> <span className='fw-bold fs-5 mt-3  hide-menu' >{user?.name}</span><br /><small className='fs-2 hide-menu' >{user?.role != "HNC" ? roles?.filter(item => item?.value == user?.role)[0]?.label : "Honc Admin"}</small></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </div>

                        {/* Sidebar navigation*/}

                        <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                            <ul id="sidebarnav">
                                {/* <li className="nav-small-cap">
                                    <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                    <span className="hide-menu">Home</span>
                                </li> */}
                                {activeMenu?.map(item => (

                                    <li className="sidebar-item" style={{
                                        cursor:'pointer'
                                    }}>
                                        <a className={`sidebar-link ${item?.children?.length > 0 ? "has-arrow" : ""} ${location.pathname === item.pathname || location.pathname + location.search === item.pathname || item?.active || item?.subRoutes?.filter(route => route == location.pathname).length > 0 ? "active" : ""}`} href={item?.pathname} onClick={() => {
                                            if (item?.children?.length > 0) {
                                                handleSelectMenuOption(item?.key)
                                            }
                                        }} aria-expanded="false">
                                            <span>
                                                <i className={item?.icon} />
                                            </span>
                                            <span className="hide-menu">{item?.label}</span>
                                        </a>
                                        {item?.children?.length > 0 ?
                                            <ul aria-expanded="false" className={`collapse first-level ${location.pathname === item.pathname || item?.active ? "in" : ""}`}>
                                                {item?.children?.map(item => (
                                                    <li className="sidebar-item">
                                                        <a href={item?.pathname} className={`sidebar-link ${location.pathname === item.pathname || item?.active || item?.subRoutes?.filter(route => route == location.pathname).length > 0 ? "active" : ""}`}>
                                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                                <i className={item?.icon} />
                                                            </div>
                                                            <span className="hide-menu">{item?.label}</span>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                            : null}
                                    </li>
                                ))}
                                <li className="sidebar-item" style={{
                                    cursor:'pointer'
                                }}>
                                    <a className="sidebar-link" onClick={() => {
                                        handleLogout()
                                    }} aria-expanded="false">
                                        <span>
                                            <i className="ti ti-logout" />
                                        </span>
                                        <span className="hide-menu">Logout</span>
                                    
                                    </a>
                                </li>
                                 
                                    {/* {(user?.role == "HNC" || user?.role == "CLT" ) ?
                                <li className="sidebar-item ">
                                    <a className="sidebar-link Stats"  aria-expanded="false" 
                                  onClick={() => {
                                                    navigate(`/Stats`)  }}>
                  
                                        <span>
                                        <i class="ti ti-chart-pie-filled"></i>
                                        </span>
                                        <span className="hide-menu " >Stats</span>
                                    
                                    </a>
                                </li>
                                 : null} */}
                         

                                

                            </ul>
                        </nav>
                    </div>
                </aside>
                {/*  Sidebar End */}
                {/*  Main wrapper */}
                <div className="body-wrapper">
                    {/*  Header Start */}
                    <header className="app-header">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse" href="javascript:void(0)">
                                        <i className="ti ti-menu-2" />
                                    </a>
                                </li>
                            </ul>
                            {/* <div className="d-block d-lg-none">
                                <img src="/dist/images/logos/dark-logo.svg" className="dark-logo" width={180} alt="" />
                            </div> */}
                            {/* <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="p-2">
                                    <i className="ti ti-dots fs-7" />
                                </span>
                            </button> */}
                            {/* <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                                <div className="d-flex align-items-center justify-content-between">
                                    <a href="javascript:void(0)" className="nav-link d-flex d-lg-none align-items-center justify-content-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilenavbar" aria-controls="offcanvasWithBothOptions">
                                        <i className="ti ti-align-justified fs-7" />
                                    </a>
                                    <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                                        <li className="nav-item dropdown">
                                            <a className="nav-link pe-0" href="javascript:void(0)" id="drop1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="user-profile-img">
                                                        <img src="/dist/images/profile/user-1.jpg" className="rounded-circle" width={35} height={35} alt="" />
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                        </nav>

                    </header>
                    {/*  Header End */}
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}
