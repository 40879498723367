import { useLocation, Navigate, Outlet } from "react-router-dom";

import React from "react";
import { useSelector } from "react-redux";

const Authorization = ({ allowedRoles }) => {
    const user = useSelector(state => (state?.reducer?.user))
    const location = useLocation();

    return allowedRoles.find((role) => user?.role?.includes(role)) ? (
        <Outlet />
    ) :
        user?._id ? (
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        ) : (
            <Navigate to="/" state={{ from: location }} replace />
        );
};

export default Authorization;
