import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import swal from 'sweetalert2';
import useApiCaller from '../hooks/useApiCaller';
import { roles } from '../constants/roles';
import { useSelector } from 'react-redux';

export default function AddAreaManager(props) {
    const { fetchData } = useApiCaller();
    const [roleCount, setRoleCount] = useState(1);
    const user = useSelector(state => state?.reducer?.user);

    const {
        register: managerRegister,
        formState: { errors: managerErrors },
        handleSubmit: managerHandleSubmit,
        setValue: managerSetValue,
        setError: managerSetError,
        reset: managerReset,
        watch: managerWatch,
        clearErrors: managerClearErrors
    } = useForm();

    const handleOnCreateManager = async (data) => {
        if ((!data.role || data.role == "")) {
            managerSetError('role', 'true');
        }
        else {
            let reqBody = {
                "name": data?.name,
                "phone": data?.phone,
                "email": data?.email,
                "password": data?.password,
                "role": data.role,
                "clientIds": [props?.clientId]
            }
            
            let newManager = await fetchData('post', 'v1/user/create', reqBody);
            if (newManager?.success) {
                managerReset();
                setRoleCount(roleCount + 1);
                document.getElementById('add-manager-close-btn').click();
                swal.fire(
                    'Successfully Created',
                    'A new Manager has been created successfully.',
                    'success'
                )
                props?.getAllManagers();
                props?.fetchAreaManagers();
                props?.fetchResponse()
            }
            else {
                swal.fire(
                    newManager?.message,
                    '',
                    'error'
                )
            }
        }
    }


    return (
        <div className="modal fade" id="addAreaManagerModal" tabIndex={-1} role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title">Create a new Manager/Admin</h5>
                        <button type="button" className="btn-close" id="add-manager-close-btn" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form class="ps-2 pe-2" onSubmit={managerHandleSubmit(handleOnCreateManager)}>
                            <div class="form-floating mb-3">
                                <input
                                    type="text"
                                    {...managerRegister("name", { required: true })}
                                    class="form-control"
                                    placeholder="Name"
                                />
                                {managerErrors.name && <small className='text-danger'>Please enter a name</small>}
                                <label><i class="ti ti-user me-2 fs-4"></i>Name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="tel"
                                    {...managerRegister('phone', { pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/i, required: true })} maxlength="10" class="form-control"
                                    placeholder="Phone Number"
                                />
                                {managerErrors.phone && <small className='text-danger'>Please enter a valid phone number</small>}
                                <label><i class="ti ti-phone me-2 fs-4"></i>Phone Number</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="email"
                                    {...managerRegister('email', { pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, required: true })} class="form-control"
                                    placeholder="Email"
                                />
                                {managerErrors.email && <small className='text-danger'>Please enter a valid email</small>}
                                <label><i class="ti ti-mail me-2 fs-4"></i>Email</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="password"
                                    {...managerRegister("password", { required: true })}
                                    class="form-control"
                                    placeholder="Password"
                                />
                                {managerErrors.password && <small className='text-danger'>Please enter a password</small>}
                                <label><i class="ti ti-lock me-2 fs-4"></i>Password</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="text"
                                    {...managerRegister("confirm_password", {
                                        required: true,
                                        validate: (val) => {
                                            if (managerWatch('password') != val) {
                                                return "Your passwords do no match";
                                            }
                                        }
                                    })}
                                    class="form-control"
                                    placeholder="CPassword"
                                />
                                {managerErrors.confirm_password && <small className='text-danger'>Your passwords do no match</small>}
                                <label><i class="ti ti-lock me-2 fs-4"></i>Confirm Password</label>
                            </div>
                            <div className='mb-3'>
                                <label>Role</label>
                                <Select
                                    key={`unique_select_key__${roleCount}`}
                                    options={user?.role == "HNC" ? roles?.filter(item => item?.priority != 2 && item?.priority != 3 && item?.priority != 4) : roles?.filter(item => item?.priority > roles?.filter(inneritem => inneritem?.value == user?.role)[0]?.priority)?.filter(item => !props?.isFromHub ? true : item?.priority != 2)} onChange={(e) => {
                                        if (e.value != "") {
                                            managerClearErrors('role');
                                        }
                                        managerSetValue('role', e.value);
                                    }} />
                                {managerErrors.role ? <small className='text-danger'>Please select a role</small> : ''}
                            </div>
                            <div class="d-md-flex align-items-center">
                                <div class="mt-3 mt-md-0 ms-auto">
                                    <button
                                        type="submit"
                                        class="btn btn-primary font-medium rounded-pill px-4"
                                    >
                                        <div class="d-flex align-items-center">
                                            <i class="ti ti-send me-2 fs-4"></i>
                                            Submit
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

