import React from 'react'
import PartnerUpdateDetails from '../modals/PartnerUpdateDetails'
import UpdateDetails from '../modals/UpdateDetails'
import Swal from 'sweetalert2'
import PartnerAadharImage from '../modals/PartnerAadharImage';
import { useState, useEffect } from 'react';
import useApiCaller from '../../hooks/useApiCaller';
import { useSelector } from 'react-redux';
export default function PartnerCard(props) {

    const { fetchData } = useApiCaller()
    const { user } = useSelector(state => state?.reducer);


    const handleActivateOrDeactivate = () => {
        const action = props?.accountStatus === "INACTIVE" ? 'Activate' : 'Deactivate';

        Swal.fire({
            title: `${action} Account`,
            text: `Are you sure you want to ${action.toLowerCase()} this partner?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: action === 'Activate' ? '#12DEB9' : '#FA896B',
            cancelButtonColor: 'red',
            confirmButtonText: `Yes, ${action}!`,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const updatedStatus = action === 'Activate' ? 'ACTIVE' : 'INACTIVE';
                const apiResult = await fetchData("POST", `v1/partner/update/${props?.partnerId}`, {
                    accountStatus: updatedStatus
                });
                if (apiResult?.success) {
                    props.fetchAllPartners();
                    Swal.fire(
                        `Account ${action === 'Activate' ? 'Activated' : 'Deactivated'}`,
                        `Your account has been ${action === 'Activate' ? 'activated' : 'deactivated'}.`,
                        'success'
                    );
                } else {
                    Swal.fire(
                        'Error',
                        'An error occurred while updating the account status.',
                        'error'
                    );
                }
            }
        });
    };
    const handleViewAadhar = () => {

        if (props.aadhar_front_image || props.aadhar_back_image) {
            props.handleSelectPartnerToUpdate(props.partnerId, 'PartnerAadharImage');
        } else {
            Swal.fire('No Aadhar Images', 'Aadhar images are not available for this partner.', 'warning');
        }
    };

    const comingSoon = () => {
        Swal.fire('Coming soon', 'this feature is coming soon', 'warning');
    }

    return (
        <div >
      
      {/* <div className={`card text-center ${user?.role === "CLT-HUB" ? "border border-dark rounded-2" : ""}`} style={{width:"20rem"}}> */}
      <div className='card text-center '>
                <div class="card-body">
                    <img
                        src={props?.profileImage || "../../dist/images/profile/user-3.jpg"}
                        style={{
                            height:90,
                            width:90
                        }}
                        class="rounded-1 img-fluid"
                    />
                    {user?.role == "HNC" || user?.role == "HNC-RGN" ?
                        <div className="position-absolute top-0 end-0 ">
                            <div className="dropdown dropstart pe-3 pt-2">
                                <a
                                    href="#"
                                    className="link text-dark"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="ti ti-dots fs-7" />
                                </a>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <li>
                                        <p
                                            className="dropdown-item"
                                            style={{
                                                cursor: 'pointer',
                                                color: props?.accountStatus === "INACTIVE" ? 'green' : 'red', // Change color based on status
                                            }}
                                            onClick={handleActivateOrDeactivate}
                                        >
                                            {props?.accountStatus === "INACTIVE" ? "Activate" : "Deactivate"}
                                        </p>
                                    </li>
                                    
                                    <li><p className="dropdown-item"
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            props?.handleSelectPartnerToUpdate(props?.partnerId, 'update');
                                        }}
                                    >
                                        Update Details
                                    </p>
                                    </li>
                                    <li>
                                        <p
                                            className="dropdown-item"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={handleViewAadhar}

                                        >
                                            View Aadhar
                                        </p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        :
                        null
                    }
                    <div class="mt-2">
                        <span
                            className={`badge ${props?.accountStatus == "INACTIVE" ? "bg-danger" : "bg-success"}`}
                        >
                            {props?.accountStatus}
                        </span>



                        <h3 class="card-title mt-3"> {props?.name}</h3>
                        <h6 class="card-subtitle text-muted fs-3"> {props?.hubName}</h6>
                    </div>
                    <div class=" mt-2 d-flex ">
                        <div class="row  d-flex ">
                            <div class=" d-flex justify-content-center">
                                <div class=" ms-0 text-start">
                                    <div className='row'>

                                        <div className='col-md-12 d-flex mt-1 gap-2'>
                                            <div className='d-flex justify-content-center mt-1'>
                                                <img src="./images/phoneicon.png" width="20" height="20"></img>
                                            </div>
                                            <div>
                                                <div className='row'>
                                                    <h6 class="fw-normal text-muted mb-0"> Phone Number</h6>
                                                </div>
                                                <div className='row'>
                                                    <h4 class="mb-0 fs-3">{props?.phone || "NA"}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-center">
                                <div class="ms-0 mt-2 text-start justify-content-center">
                                    <div className='col-md-12 d-flex gap-1'>
                                    {user?.role == "HNC" || user?.role == "HNC-RGN" ?
                                    <>
                                        <div className=' d-flex justify-content-center  ' style={{ marginTop: "2px" }}>
                                            <img src="./images/Aadharicon.png" width="32" height="32" ></img>
                                        </div>
                                                    <div>
                                                    <div className='row'>
                                                        <h6 class="fw-normal text-muted mb-0 mt-1">Aadhar Number</h6>
                                                    </div>
                                                    <div className='row'>
                                                        <h4 class="mb-0 fs-4">{props?.aadhar_num || "NA"}</h4>
                                                    </div>
                                                </div>
                                                </>
                                                :
                                              null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                        
                            {user?.role == "HNC" || user?.role == "HNC-RGN"?
                                                        <div class=" d-flex justify-content-center">
                                                        <div class="ms-0 mt-2 text-start justify-content-center">
                                                            <div className='col-md-12 d-flex gap-1'>
                                                                <div className=' d-flex justify-content-center  ' style={{ marginTop: "2px" }}>
                                                                    <img src="./images/otp.png" width="32" height="32" ></img>
                                                                </div>
                                                                <div>
                                                                    <div className='row'>
                                                                        <h6 class="fw-normal text-muted mb-0 mt-1 me-5">OTP</h6>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <h4 class="mb-0 fs-4">{props?.otp || "NA"}</h4>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                            }

</div>
                          
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
