import React from 'react';
import ReactApexChart from 'react-apexcharts';

const JobsRadialbars = (props) => {
  // Calculate the total sum of all series values
  const totalValue = props.completed + props.incomplete + props.not_attempted;
  const completedPercentage = ((props.completed / totalValue) * 100).toFixed(1);
  const incompletePercentage = ((props.incomplete / totalValue) * 100).toFixed(1);
  const notAttemptedPercentage = ((props.not_attempted / totalValue) * 100).toFixed(1);


  const options = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%', // Adjust this to change the size of the hollow circle
        },
        dataLabels: {
          name: {
            fontSize: '16px',
          },
          value: {
            fontSize: '12px',
          },
          total: {
            show: true,
            label: 'Total',
            // formatter: function (w) {
            //   // Display the total sum of all series values
            //   return totalValue.toFixed(1);
            // },
            formatter: function (w) {
              // Display the total sum of all series values if it's a valid number, otherwise display 0
              return !isNaN(totalValue) ? totalValue : 0;
            },
          },
        },
      },
    },
    series: [completedPercentage, incompletePercentage, notAttemptedPercentage],
   
    labels: ['Completed', 'Incomplete', 'Not Attempted'],
    tooltip: {
      enabled: false, // Disable the default tooltip
    },
  };

  return (
    <div className="radial-chart">
      <ReactApexChart options={options} series={options.series} type="radialBar" height={300} />
    </div>
  );
};

export default JobsRadialbars;
