import { actionTypes } from "../constants/reduxConstants"

export const setUser = (user) => dispatch => {
    dispatch({
        type: actionTypes.SET_USER,
        user
    })
}

export const resetPersistStore = () => dispatch => {
    dispatch({
        type: actionTypes.RESET,
    })
}