import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import useApiCaller from '../hooks/useApiCaller';
import Swal from 'sweetalert2';
import JobsRadialbars from '../components/mini/JobsRadialbars';
import Select from 'react-select';
import AddonjobsRadialBar from '../components/mini/AddonjobsRadialBar';


const Stats = () => {
  // const [firstArrayElement, setFirstArrayElement] = useState(null);
  const [options] = useState({});
  const [series] = useState([44, 55]);
  const [labels] = useState(['A', 'B']);
  const user = useSelector(state => state?.reducer?.user);
  const { fetchData } = useApiCaller()
  const [addonJobs, setAddonJobs] = useState([]);
  const [jobs, setJobs] = useState([])
  const [hubs, setHubs] = useState([])
  const [selectedHub, setSelectedHub] = useState('');
  const [hubOptions, setHubOptions] = useState([]);
  const [hubStats, setHubStats] = useState([]);
  const [isLoadingHubStats, setIsLoadingHubStats] = useState(false);
  const [selectedCityData, setSelectedCityData] = useState(null)
 
  const [selectedCityName, setSelectedCityName] = useState(null)
  

  // useEffect(() => {
  //   const fetchDataAndSetFirstElement = async (cityId) => {
  //     try {
  //       let reqbody={
  //         cityId:cityId
  //       }
  //       const response = await fetchData('POST', 'v1/job/statspercity', reqbody);

  //       // Assuming response.data is an array
  //       if (Array.isArray(response.data) && response.data.length > 0) {
  //         setFirstArrayElement(response.data[1]);
  //       } else {
  //         console.log('No data available.');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchDataAndSetFirstElement();
  // }, []);


  const getAlljobsstats = async (cityId) => {
    try {
     
      const response = await fetchData('post', 'v1/job/stats');
      if (response?.success) {
       
        setJobs(response.data);
      } else {
        Swal?.fire('Warning!', 'Something went wrong.', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal?.fire('Error!', 'Failed to fetch data from the API.', 'error');
    }
  };
  useEffect(() => {
    getAlljobsstats();
  }, []);



  const Hubs = async (cityId) => {
    try {
      let reqbody = {
        cityId: cityId,

      };

      let hubsResponse = await fetchData('POST', 'v1/job/statspercity', reqbody);

      if (hubsResponse?.success) {
        setHubs(hubsResponse?.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    Hubs();
  }, []);



  const getAllAddonjobsstats = async () => {
    try {
      const response = await fetchData('post', 'v1/addonjobs/stats');
      if (response?.success) {
        // Assuming the data you want to display is in response.data
        setAddonJobs(response.data);
      } else {
        Swal?.fire('Warning!', 'Something went wrong.', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal?.fire('Error!', 'Failed to fetch data from the API.', 'error');
    }
  };
  useEffect(() => {
    getAllAddonjobsstats();
  }, []);




  const fetchHubNames = async () => {
    try {
      const response = await fetchData('post', 'v1/job/citieslist');
      if (response?.success) {
    
        setHubOptions(response.data?.map(item => ({ label: item?.cityName, value: item?.id })));

      } else {
        Swal?.fire('Warning!', 'Something went wrong.', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal?.fire('Error!', 'Failed to fetch data from the API.', 'error');
    }
  };

  useEffect(() => {
    fetchHubNames();
  }, []);

  const clearCitySelection = () => {
 
    setSelectedCityData(null);
    setSelectedCityName(null);
    setHubs( hubs);
   
  };


  const handleHubChange = async (cityId) => {
    try {
      let reqbody = {
        cityId: cityId,
        // startDate: '2023-09-03T18:30:59.999Z',
      };

      let hubsResponse = await fetchData('POST', 'v1/job/statspercity', reqbody);

      if (hubsResponse?.success) {
       setSelectedCityData(hubsResponse.data);
     

        setSelectedCityName(hubOptions.find(option => option.value === cityId)?.label);

      }
    } catch (error) {
      console.error('Error:', error);
    }
  }




  return (
    <div>
      {/* <div>
      <h1>First Array Element:</h1>
      {firstArrayElement ? (
        <table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(firstArrayElement).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </div> */}
        <div className="row">
        <div className="d-flex align-items-center">
                <h4 className="card-title mb-0 fs-6">
                  Daily Cleaning
                  <span className='fs-8 ms-1'>
                    {' ('}
                    {isNaN(jobs?.completed + jobs?.incomplete) ? 0 : (jobs?.completed + jobs?.incomplete)}
                    /{isNaN(jobs?.total) ? 0 : (jobs?.total)}
                    {')'}
                  </span>

                </h4>

              </div>
              </div>
              <div className='row mt-4'>
              <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-info ">
                        <h3 class="text-info box mb-0">
                        <img src="./images/caution.png" width="29" height="29"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3  class="text-success mb-0 fs-6"> {jobs?.not_attempted || 0}</h3>
                        <h6 className="text-muted fw-normal  " style={{ marginTop: "2px" }}>   <span class="text-muted">Not_Attempted</span></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-info just">
                        <h3 class="text-info box mb-0">
                        <img src="./images/correct.png" width="24" height="24"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3  class="text-success mb-0 fs-6"> {jobs?.completed || 0}</h3>
                        <h6 className="text-muted fw-normal  " style={{ marginTop: "2px" }}>   <span class="text-muted">Completed</span></h6>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-info">
                        <h3 class="text-success box mb-0">
                        <img src="./images/Not Complete.png" width="20" height="20"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3 class="text-success mb-0 fs-6">{jobs?.incomplete ||0}</h3>
                        <span class="text-muted">Incomplete</span>
                      </div>
                    </div>
                  </div>
                </div>
               
              
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-primary">
                        <h3 class="text-primary box mb-0">
                        <img src="./images/Not_Approved.png" width="20" height="20"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3 class="text-primary mb-0 fs-6">{jobs?.not_approved || 0}</h3>
                        <span class="text-muted">Not_Approved</span>
                      </div>
                    </div>
                  </div>
                </div>
              
            
               
              </div>


              {/* <div class="row">
        <div className="d-flex align-items-center">
                <h4 className="card-title mb-0 fs-6">
                  Vaccum Cleaning
                  <span className='fs-8 ms-1'>
                    {' ('}
                    {isNaN(addonJobs?.completed + addonJobs?.incomplete) ? 0 : (addonJobs?.completed + addonJobs?.incomplete)}
                    /{isNaN(addonJobs?.total) ? 0 : (addonJobs?.total)}
                    {')'}
                  </span>

                </h4>

              </div>
              </div>
              <div className='row mt-4'>
              <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-primary">
                        <h3 class="text-primary box mb-0">
                        <img src="./images/caution.png" width="29" height="29"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3 class="text-primary mb-0 fs-6">{addonJobs?.not_attempted || 0}</h3>
                        <span class="text-muted">Not_Attempted</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-info just">
                        <h3 class="text-info box mb-0">
                        <img src="./images/correct.png" width="24" height="24"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3  class="text-success mb-0 fs-6"> {addonJobs?.completed || 0}</h3>
                        <h6 className="text-muted fw-normal  " style={{ marginTop: "2px" }}>   <span class="text-muted">Completed</span></h6>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-info">
                        <h3 class="text-success box mb-0">
                        <img src="./images/Not Complete.png" width="20" height="20"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3 class="text-success mb-0 fs-6">{addonJobs?.incomplete ||0}</h3>
                        <span class="text-muted">Incomplete</span>
                      </div>
                    </div>
                  </div>
                </div>
               
              
                <div class="col-lg-3 col-md-3">
                  <div class="card overflow-hidden">
                    <div class="d-flex flex-row">
                      <div class="p-3 bg-light-primary">
                        <h3 class="text-primary box mb-0">
                        <img src="./images/Not_Approved.png" width="22" height="22"></img>
                        </h3>
                      </div>
                      <div class="p-3">
                        <h3 class="text-primary mb-0 fs-6">{addonJobs?.not_approved || 0}</h3>
                        <span class="text-muted">Not_Approved</span>
                      </div>
                    </div>
                  </div>
                </div>
              
            
               
              </div> */}

      {/* <div className='row'>
  
        <div className="col-md-6 col-lg-6 d-flex align-items-stretch ">
          <div className="card w-100 rounded-bottom-0 ">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <h4 className="card-title mb-0 fs-6">
                  Daily Cleaning
                  <span className='fs-8 ms-1'>
                    {' ('}
                    {isNaN(jobs?.completed + jobs?.incomplete) ? 0 : (jobs?.completed + jobs?.incomplete)}
                    /{isNaN(jobs?.total) ? 0 : (jobs?.total)}
                    {')'}
                  </span>

                </h4>

              </div>
              <div className="d-flex align-items-center my-3">
              
                <div className=" border-end pe-3">
                  <div className='d-flex justify-content-center'>
                    <img src="./images/Complete.png" width="20" height="20"></img>
                    <h6 className="text-muted fw-normal ps-2 " style={{ marginTop: "2px" }}>Completed</h6>
                  </div>
                  <b className='text-center d-flex justify-content-center fs-8'>{jobs?.completed}</b>
                </div>
                <div className="ms-3 pe-5">
                  <div className='d-flex justify-content-center'>
                    <img src="./images/Not Complete.png" width="20" height="20"></img>
                    <h6 className="text-muted fw-normal ps-2" style={{ marginTop: "2px" }}>Incomplete</h6>
                  </div>
                  <b className='text-center d-flex justify-content-center fs-8'>{jobs?.incomplete}</b>
                </div>
              </div>

            </div>
          
          </div>
        </div>


        <div className="col-md-6 col-lg-6 d-flex align-items-stretch">
          <div className="card w-100 rounded-bottom-0">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <h4 className="card-title mb-0 fs-6">
                  Vaccum Cleaning
                  <span className='fs-8 ms-1'>
                    {' ('}
                    {isNaN(addonJobs?.completed + addonJobs?.incomplete) ? 0 : (addonJobs?.completed + addonJobs?.incomplete)}
                    /{isNaN(addonJobs?.total) ? 0 : (addonJobs?.total)}
                    {')'}
                  </span>
                </h4>

              </div>

              <div className="d-flex align-items-center my-3">
            
                <div className="ms-5 border-end  pe-5">
                  <div className='d-flex justify-content-center'>
                    <img src="./images/Complete.png" width="20" height="20"></img>
                    <h6 className="text-muted fw-normal ps-2" style={{ marginTop: "2px" }}>Completed</h6>
                  </div>
                  <b className='text-center d-flex justify-content-center fs-8'>
                    {addonJobs?.completed !== null && addonJobs?.completed !== undefined ? (
                      <div>{addonJobs.completed}</div>
                    ) : (
                      <div style={{ fontSize: "12px", marginRight: "-20px" }}>Not available</div>
                    )}</b>
                </div>
                <div className="ms-5 pe-3">
                  <div className='d-flex justify-content-center'>
                    <img src="./images/Not Complete.png" width="20" height="20"></img>
                    <h6 className="text-muted fw-normal ps-2" style={{ marginTop: "2px" }}>Incomplete</h6>
                  </div>
                  <b className='text-center d-flex justify-content-center fs-8'>
                    {addonJobs?.incomplete !== null && addonJobs?.incomplete !== undefined ? (
                      <div>{addonJobs.incomplete}</div>
                    ) : (
                      <div style={{ fontSize: "12px", marginRight: "-20px" }}>Not available</div>
                    )}</b>
                </div>
              </div>

            </div>
          
          </div>
        </div>
      </div> */}

      {user?.role != "CLT-HUB" ?
        (<div class="col-md-12 col-lg-12 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-3">
                <div class="mb-3 mb-sm-0">
                  <h4 class="mb-0 fw-semibold">{selectedCityName ? ` ${selectedCityName}` : ''}</h4>


                </div>
                <div>


                <div className="d-flex" style={{ gap: "10px" }}>
                <a href="javascript:void(0)" id="btn-clear-cities" className="btn btn-info d-flex align-items-center" onClick={clearCitySelection}>
          <i className="ti ti-trash text-white me-1 fs-5" /> Clear cities
        </a>
              <Select
                    className="fw-semibold"
                    onChange={(selectedOption) => handleHubChange(selectedOption.value)}
                    options={hubOptions}
                    placeholder="Select a hub"
                  />
            </div>

               

                </div>
              </div>

              <div class="table-responsive">
                {selectedCityData ? (
                  <table class="table align-middle text-nowrap mb-0">
                    <thead>
                      <tr class="text-muted fw-semibold">
                        <th scope="col">Hubs</th>
                        <th scope="col" className='text-center '>Completed</th>
                        <th scope="col" className='text-center'>Incomplete</th>
                        <th scope="col" className='text-center'>Not Attempted</th>
                        <th scope="col" className='text-center'>Total</th>
                      </tr>
                    </thead>
                    <tbody className='border-top'>
                      {selectedCityData?.map((item) => (
                        <tr>
                          <td className='ps-0 '>{item?._id}</td>
                          <td className='ps-0 text-center '>{item?.completed}</td>
                          <td className='ps-0 text-center'>{item?.incomplete}</td>
                          <td className='ps-0 text-center'>{item?.not_attempted}</td>
                          <td className='ps-0 text-center'>{item?.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div class="table-responsive">
                    {hubs.length > 0 ? (

                      <table class="table align-middle text-nowrap mb-0">

                        <thead>
                          <tr class="text-muted fw-semibold">
                            <th scope="col"> hub</th>
                            <th scope="col" className='text-center '>Completed</th>
                            <th scope="col" className='text-center'>Incomplete</th>
                            <th scope="col" className='text-center'>Not Attempted</th>
                            <th scope="col" className='text-center'>Total</th>

                          </tr>
                        </thead>
                        <tbody className='border-top'>
                          {hubs.map((item) => (
                            <tr key={item._id}>
                              <td className='ps-0 '>{item._id}</td>
                              <td className='ps-0 text-center '>{item.completed}</td>
                              <td className='ps-0 text-center'>{item.incomplete}</td>
                              <td className='ps-0 text-center'>{item.not_attempted}</td>
                              <td className='ps-0 text-center'>{item.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      null
                    )}

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>) : null}

      <div className='row'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='d-flex justify-content-center align-items-center '>
              <h4 className='mt-4'>Daily Cleaning</h4>
            </div>
            <div className='row '>
              <div className='col-lg-8'>
                <JobsRadialbars completed={jobs.completed}
                  incomplete={jobs.incomplete}
                  not_attempted={jobs.not_attempted} />
              </div>
              <div className='col-lg-4 mt-4 ms-auto'>
                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#feb019", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>


                  <span>NotAttempted: {jobs?.not_attempted}</span>
                </div>
                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#00e396", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>
                  <span>Completed: {jobs?.completed}</span>
                </div>

                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#008fff", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>
                  <span>Incomplete: {jobs?.incomplete}</span>
                </div>

              </div>
            </div>

          </div>
        </div>

        {/* <div className='col-md-6'>
          <div className='card'>
            <div className='d-flex justify-content-center align-items-center' >
              <h4 className='mt-4'>Vaccum Cleaning</h4>
            </div>
            <div className='row'>
              <div className='col-lg-8'>
                <AddonjobsRadialBar completed={addonJobs.completed}
                  incomplete={addonJobs.incomplete}
                  not_attempted={addonJobs.not_attempted} />
              </div>
              <div className='col-lg-4 mt-4 ms-auto' >
                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#feb019", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>


                  <span>NotAttempted: {addonJobs?.not_attempted}</span>
                </div>
                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#00e396", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>
                  <span>Completed: {addonJobs?.completed}</span>
                </div>

                <div>
                  <span class=" d-inline-block me-2" style={{ backgroundColor: "#008fff", width: "10px", height: "10px", display: "inline-block", fillOpacity: "0.85" }}></span>
                  <span>Incompleted: {addonJobs?.incomplete}</span>
                </div>

              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>

)
}

export default Stats