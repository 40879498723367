import Select from "react-select";
import useApiCaller from "../../hooks/useApiCaller";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SelectComponent from "../SelectComponent";

const HoncAreaMangerslist = (props) => {
  const { fetchData } = useApiCaller();
  const user = useSelector((state) => state?.reducer?.user);
  const options = props?.remainingClientIds?.map(client => ({
    label: client.label,
    value: client.value,
  }));
  const handleClick = () => {
    console.log('snehitha option:', props?.selectedOption);
    props?.handleAssignClients(props?.managerId);
    document.getElementById("asiign-client-close-btn").click()
  };

  return (
    <div className="modal fade" id="assignClientModal" tabIndex={-1} role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center">
            <h5 className="modal-title">Assign a Client</h5>
            <button
              type="button"
              className="btn-close"
              id="asiign-client-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
         
          <h5><b>Manager Name: </b>{props?.managerName}</h5>
          
            <Select
              isMulti
              options={options} 
              value={props?.selectedOption}
              onChange={props?.onChange}
              isSearchable={true}
              placeholder="Select an option"
              
            />
          </div>
          <div className="col-md-8 col-xl-12 text-end d-flex  justify-content-center mt-3 mt-md-0 mb-2">
            <a
              href="javascript:void(0)"
              id="btn-add-contact"
              className="btn btn-info d-flex align-items-center"
              // onClick={() =>{  console.log('snehitha option:', props?.selectedOption);
              //  props?.handleAssignClients(props?.managerId)}}
              onClick={handleClick}
            >
              Assign Manager
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoncAreaMangerslist;
