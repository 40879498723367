import React from 'react'
import { useForm,useWatch } from 'react-hook-form';
import useApiCaller from '../../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import "../../styles/Badges.css"

const UpdateDetails = (props) => {
  const[isLoading,setIsLoading]=useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { fetchData } = useApiCaller();
  const handleOnUpdatedetails = async (data) => {
      
    const confirmationResult = await swal.fire({
      title: 'Are you ready to update?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (confirmationResult.isConfirmed) {
    let reqBody = {
      "name": data.name,
     "aadhar_num": data.aadhar_num,
    }
    setIsLoading(true); 
   try{
    let PartnerUpdatedetails = await fetchData('POST', `v1/partner/update/${props?.partnerId}`, reqBody);
console.log("Updatedetails",PartnerUpdatedetails)
    if (PartnerUpdatedetails?.success) {
      document.getElementById(`clsPUBtn${props?.partnerId}`).click();
      props?.fetchAllPartners();
      await swal.fire('Success', 'Details updated successfully!', 'success');
    } else {
      swal.fire('Something went wrong', '', 'error');
    }
  }catch (error) {
    console.error("Error:", error);
    swal.fire('Error occurred', 'Please try again later.', 'error');
  } finally {
    setIsLoading(false); // Set loading back to false after the update is done
  }
  
  }else {
    setIsLoading(false); // Set loading back to false if the user cancels
  }
}
  useEffect(() => {
    setValue('name', props.name || '');
    setValue('aadhar_num', props.aadhar_num || '');
  }, [props.name, props.aadhar_num]);



  return (
    <div>
      <div
        className="modal fade"
        id={`partnerUpdateDetails`}
        tabIndex="-1"
        aria-labelledby="UpdateDetails"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5>Update Details
              </h5>
              <button type="button" id={`clsPUBtn${props?.partnerId}`} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
            {isLoading ? (
          <p>Updating the detials,please wait ....
          </p>
        ) : (
              <form id="addContactModalTitle" onSubmit={handleSubmit(handleOnUpdatedetails)}>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 contact-name">
                      <label className='UpdatePartnerdetails'> <img src="./images/Partnericon.png" width="17" className='mb-1'></img>Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        {...register('name', { required: true })}
                        className="form-control"
                        value={watch('name')}
                        placeholder="Enter name"
                     
                      />
                         
                      {errors.name && <p><small className='text-danger'>Please enter name.</small></p>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-12">
                    <div className="mb-3 contact-email">
                      <label><img src="./images/Aadharicon.png" width="30" className='mb-1' ></img> Aadhaar<span className='text-danger'>*</span></label>
                      <input
  type="text"
  {...register('aadhar_num', {
    pattern: /^\d{12}$/,
    required: true,
    validate: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Please enter only digits for the Aadhaar number';
      }
      return true;
    },
  })}
  maxLength="12"
  className="form-control"
  placeholder="Enter Aadhaar number"
  value={watch('aadhar_num')}

/>



                      {errors.aadhar_num && <p className="error-message">{errors.aadhar_num.message}</p>}


                      {errors.aadhar_num && <p><small className='text-danger'>Please enter a valid Aaadhar number.</small></p>}

                    </div>
                  </div>
                </div>
                <input type="submit" className="btn btn-success px-4" />


              </form>
        )}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateDetails