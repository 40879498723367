import React from 'react'
import { useSelector} from 'react-redux';
import HoncAreaMangerslist from '../modals/HoncAreaMangerslist';
import{useState} from "react"

const HoncAreaManagers = (props) => {
    const user = useSelector(state => state?.reducer?.user);
 
    const handleClientSelection = (selectedClients) => {
        console.log("Selected Clients:", selectedClients);
        // You can perform additional actions with the selected clients here
      };
      console.log(HoncAreaMangerslist)
  return (
    <div className='row'>
        <div className=''>
        <div className="card" style={{ width: '250px', height: '250px' }}>
   
    <div className='d-flex flex-row pt-4 ps-4 justify-content-between align-items-center'>
        <h4 className='mb-0 '>{props?.name}</h4>
        <div className="dropdown dropstart">
            <a href="#" className="link text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="ti ti-dots fs-7 pe-3" />
            </a>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                <li>
                    <p
                                        
                                         className="dropdown-item"
                                         style={{
                                            cursor: 'pointer',
                                            color: 'black' // Change color based on status
                                        }}
                                       
                                        onClick={() => {
                                            props?.handleSelectManagerToUpdate(props?.managerId, 'assignclient');
                                        }} 
                                    
                                        >
                       
                      
                    
                      Manage Clients
                    </p>
            
               
               </li>
             

              
           
            </ul>
        </div>

    </div>

    <div className="p-4 pt-0 pb-2">
        <div>
       
          
            <h6 className="mb-0 text-muted">
                {props?.email}
            </h6>
            <h6 className="mb-0 text-muted">
                {props?.phone}
            </h6>
           
          
              {/* {props?.remainingClientIds.map(item => (
                            <button
                                key={item?.value}
                                className="btn btn-outline-primary me-3 mt-2"
                                style={{
                                    padding: '1px 12px',
                                    borderRadius: '50px'
                                }}
                            >
                                {item?.label}
                            </button>
                        ))} */}
            {user?.role == "HNC" ?
                <h6 className="mb-0 text-muted mt-2">
                    <b>Password: </b><br />{props?.password}
                </h6>
                :
                null
            }

        </div>
    </div>
    <div className='ps-4 pe-4 pb-4'>
        {props?.client?.map(item => (
            <button className="btn btn-outline-primary me-3 mt-2"
                style={{
                    padding: '1px 12px',
                    borderRadius: '50px'
                }}
            >
                {item?.name}
            </button>
        ))}
    </div>
</div >

</div>



        
</div>
  )
}

export default HoncAreaManagers