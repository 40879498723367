const env = "PRODUCTION" //["DEVELOPMENT","PRODUCTION","LOCAL","TESTING"];
const API_URL = env == "DEVELOPMENT" ? "https://test-gateway.honc.io/" : "https://in-gateway.honc.io/";
// const API_URL = "http://192.168.0.129:6098/";
const CIRCLE_CUSTOMER_API_URL = env == "DEVELOPMENT" ? "https://test-gateway.honc.io/" : "https://in-gateway.honc.io/";
const HUB_PARTNER_API_URL = env == "DEVELOPMENT" ? "https://test-gateway.honc.io/hubpartner/" : "https://in-gateway.honc.io/hubpartner/";
const ADMIN_API_URL = env == "DEVELOPMENT" ? "https://devdashboardapi.honc.io/" : "https://adminapi.honc.io/";
const CUSTOMER_API_URL = env == "DEVELOPMENT" ? "https://devapi.honc.io/" : "https://api.honc.io/";

export {
    env,
    API_URL,
    CIRCLE_CUSTOMER_API_URL,
    ADMIN_API_URL,
    CUSTOMER_API_URL,
    HUB_PARTNER_API_URL
}