import React, { Suspense, lazy, useEffect, useState } from 'react'
import PageBreadCrumb from '../components/PageBreadCrumb';
import useApiCaller from '../hooks/useApiCaller';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import AddonJobsList from '../components/AddonJobsList';
import ManagerCard from '../components/mini/ManagerCard';




const ManagersList = lazy(() => import("../components/ManagersList"));
const PartnersList = lazy(() => import("../components/PartnersList"));
const PackagesList = lazy(() => import("../components/PackagesList"));
const VehiclesList = lazy(() => import("../components/VehiclesList"));
const JobsList = lazy(() => import("../components/JobsList"));

export default function HubDashboard(props) {


    const [hubDetails, setHubDetails] = useState({});

    const queryParams = new URLSearchParams(window.location.search);
    const hubId = queryParams.get('id');

    const { fetchData } = useApiCaller();
    const [activeTab, setActiveTab] = useState("Managers");
    const user = useSelector(state => state?.reducer?.user);


    const [tabs, setTabs] = useState([
       
        user?.role !== "CLT-HUB"
        ? {
            label: "Partners",
            icon: "ti ti-user",
          }
        : null,
        user?.role !== "CLT-HUB"
        ? {
            label: "Vehicles",
            icon: "ti ti-car",
          }
        : null,
        {
            label: "Jobs",
            icon: "ti ti-file-description"
        },
        // {
        //     label: "Vacuuming",
        //     icon: "ti ti-checklist"
        // }
    ]);
    

    useEffect(() => {
        getHubDetails();

        if (user?.role == "HNC" || user?.role == "HNC-RGN") {
            setTabs([
                {
                    label: "Managers",
                    icon: "ti ti-users"
                },
                {
                    label: "Packages",
                    icon: "ti ti-receipt"
                }, ...tabs
            ])
            setActiveTab('Managers');
        }
        else if(user?.role == "CLT" || user?.role == "CLT-RGN"){
            setTabs([
                {
                    label: "Managers",
                    icon: "ti ti-users"
                }, ...tabs
            ])
            setActiveTab('Managers');
        }
        else {
            setTabs([
                {
                    label: "Jobs",
                    icon: "ti ti-users"
                },
                // {
                //     label: "Addon Jobs",
                //     icon: "ti ti-receipt"
                // }

            ])
            setActiveTab('Jobs');
        }
    }, [])

    const getHubDetails = async () => {
        let hubDetails = await fetchData('get', `v1/hub/get/${hubId}`);
        if (hubDetails?.success) {
            setHubDetails(hubDetails?.data);
        }
    }

    const renderSection = () => {
        switch (activeTab) {
            case "Managers":
                return <ManagersList
                    isFromHub={true}
                    hubId={hubDetails?._id}
                    clientId={hubDetails?.clientId}
                    cityId={hubDetails?.cityId}

                />;
           
                case "Partners":
                    if (user?.role === "CLT-HUB") {
                      // Return null or an empty component if user.role is CLT-HUB
                      return null; // or <></> or any other placeholder component
                    } else {
                      // Render <VehiclesList /> for other user roles
                      return <PartnersList
                      isFromHub={true}
                      hubId={hubDetails?._id}
                      clientId={hubDetails?.clientId}
                  />;
                    }
                  
                
            case "Packages":
                return <PackagesList
                    isFromHub={true}
                    hubId={hubDetails?._id}
                    clientId={hubDetails?.clientId}
                />;
                case "Vehicles":
                    if (user?.role === "CLT-HUB") {
                      // Return null or an empty component if user.role is CLT-HUB
                      return null; // or <></> or any other placeholder component
                    } else {
                      // Render <VehiclesList /> for other user roles
                      return (
                        <VehiclesList
                          isFromHub={true}
                          hubId={hubDetails?._id}
                          clientId={hubDetails?.clientId}
                        />
                      );
                    }
                  
            case "Jobs":
                return <JobsList
                    isFromHub={true}
                    hubId={hubDetails?._id}
                    clientId={hubDetails?.clientId}
                />;
            case "Vacuuming":
                return <AddonJobsList
                    isFromHub={true}
                    hubId={hubDetails?._id}
                    clientId={hubDetails?.clientId}
                />
            default:
                return null;
        }
    }

    return (
        <div className="container-fluid">
            <PageBreadCrumb
                pageTitle={hubDetails?.name}
                breadCrumbs={user.role == "CLT-HUB" ?
                    [
                        {
                            label: 'Hub Dashboard',
                            link: '#'
                        }
                    ]
                    :
                    user.role == "CLT-RGN" || user.role == "CLT" ?
                        [
                            {
                                label: 'Client Dashboard',
                                link: `/client-dashboard?id=${hubDetails?.clientId?._id}`
                            },
                            {
                                label: 'Hub Dashboard',
                                link: '#'
                            }
                        ]
                        :
                        [
                            {
                                label: 'Clients',
                                link: '/clients'
                            },
                            {
                                label: 'Client Dashboard',
                                link: `/client-dashboard?id=${hubDetails?.clientId?._id}`
                            },
                            {
                                label: 'Hub Dashboard',
                                link: '#'
                            }
                        ]
                }
                logo={hubDetails?.clientId?.logo}
            />
            <div className="card">
                <div className="card-body">
                    <div>
                        {/* Nav tabs */}
                        <ul className="nav nav-tabs mb-4" role="tablist">
                            {tabs?.map(item => (
                                <li className="nav-item" key={item?.label} style={{
                                    cursor: 'pointer'
                                }} >
                                    <a className={`nav-link d-flex ${activeTab == item?.label ? "active" : ""}`} onClick={() => {
                                        setActiveTab(item?.label)
                                    }} data-bs-toggle="tab" role="tab">
                                        <span><i className={`${item?.icon} fs-4`} />
                                        </span>
                                        <span className="d-none d-md-block ms-2">{item?.label}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" role="tabpanel">
                                {!_.isEmpty(hubDetails) ?
                                    <Suspense
                                        fallback={
                                            <div>
                                                <h4>Loading ... </h4>
                                            </div>
                                        }
                                    >
                                        {renderSection()}
                                    </Suspense>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
