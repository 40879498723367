export const actionTypes = {
    SIMPLE_ACTION: "SIMPLE_ACTION",
    SET_CLIENT_PAGE_NUM: "SET_CLIENT_PAGE_NUM",
    SET_CLIENT_PAGE_SIZE: "SET_CLIENT_PAGE_SIZE",
    SET_CLIENT_SEARCH_TEXT: "SET_CLIENT_SEARCH_TEXT",
    SET_VEHICLE_PAGE_NUM: "SET_VEHICLE_PAGE_NUM",
    SET_VEHICLE_PAGE_SIZE: "SET_VEHICLE_PAGE_SIZE",
    SET_VEHICLE_SEARCH_TEXT: "SET_VEHICLE_SEARCH_TEXT",
    SET_JOBS_PAGE_NUM: "SET_JOBS_PAGE_NUM",
    SET_JOBS_PAGE_SIZE: "SET_JOBS_PAGE_SIZE",
    SET_JOBS_SEARCH_TEXT: "SET_JOBS_SEARCH_TEXT",
    
    SET_COMPLETEDJOBS_PAGE_NUM:" SET_COMPLETEDJOBS_PAGE_NUM",
    SET_COMPLETEDJOBS_PAGE_SIZE:" SET_COMPLETEDJOBS_PAGE_SIZE",
    SET_COMPLETEDJOBS_SEARCH_TEXT:" SET_COMPLETEDJOBS_SEARCH_TEXT",

    SET_NOT_ATTEMPTEDJOBS_PAGE_NUM:"  SET_NOT_ATTEMPTEDJOBS_PAGE_NUM",
    SET_NOT_ATTEMPTEDJOBS_PAGE_SIZE:"  SET_NOT_ATTEMPTEDJOBS_PAGE_SIZE",
    SET_NOT_ATTEMPTEDJOBS_PAGE_TEXT:" SET_NOT_ATTEMPTEDJOBS_PAGE_TEXT",
     
    
    SET_INCOMPLETEJOBS_PAGE_NUM:"  SET_INCOMPLETEJOBS_PAGE_NUM",
    SET_INCOMPLETEJOBS_PAGE_SIZE:"   SET_INCOMPLETEJOBS_PAGE_SIZE",
    SET_INCOMPLETEJOBS_PAGE_TEXT:" SET_INCOMPLETEJOBS_PAGE_TEXT",
    
    SET_APPROVALPENDING_PAGE_NUM:"  SET_APPROVALPENDING_PAGE_NUM",
    SET_APPROVALPENDING_PAGE_SIZE:"  SET_APPROVALPENDING_PAGE_SIZE",
    SET_APPROVALPENDING_PAGE_TEXT:"  SET_APPROVALPENDING_PAGE_TEXT",

    SET_ADDON_COMPLETEDJOBS_PAGE_NUM: "SET_ADDON_COMPLETEDJOBS_PAGE_NUM",
    SET_ADDON_COMPLETEDJOBS_PAGE_SIZE: " SET_ADDON_COMPLETEDJOBS_PAGE_SIZE",
    SET_ADDON_COMPLETEDJOBS_SEARCH_TEXT: " SET_ADDON_COMPLETEDJOBS_SEARCH_TEXT",

    SET_ADDON_INCOMPLETEDJOBS_PAGE_NUM: "SET_ADDON_INCOMPLETEDJOBS_PAGE_NUM",
    SET_ADDON_INCOMPLETEDJOBS_PAGE_SIZE: " SET_ADDON_INCOMPLETEDJOBS_PAGE_SIZE",
    SET_ADDON_INCOMPLETEDJOBS_SEARCH_TEXT: " SET_ADDON_INCOMPLETEDJOBS_SEARCH_TEXT",

    SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_NUM: " SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_NUM",
    SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_SIZE: "  SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_SIZE",
    SET_ADDON_NOT_ATTEMPTEDJOBS_SEARCH_TEXT: "SET_ADDON_NOT_ATTEMPTEDJOBS_SEARCH_TEXT",

    
    SET_ADDON_APPROVALPENDING_PAGE_NUM: "  SET_ADDON_APPROVALPENDING_PAGE_NUM",
    SET_ADDON_APPROVALPENDING_PAGE_SIZE: "   SET_ADDON_APPROVALPENDING_PAGE_SIZE",
    SET_ADDON_APPROVALPENDING_SEARCH_TEXT: " SET_ADDON_APPROVALPENDING_SEARCH_TEXT",



    RESET: "RESET"
}