import React, { useState, useEffect } from 'react'
import Table from '../components/Table'
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVehiclePageSize, setVehiclePageNum, setVehicleSearchText } from '../redux/nonPersistActions'
import useApiCaller from '../hooks/useApiCaller';
import AddVechile from './modals/AddVechile'
import moment from 'moment';


export default function VehiclesList(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { fetchData } = useApiCaller();
    const { vehiclePageNum, vehiclePageSize, vehicleSearchText } = useSelector(state => state?.nonPersistReducer);
    const [searchText, setSearchText] = useState('');

    const [vehicle, setVehicle] = useState([]);
    const [totalVehicle, setTotalVehicle] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const { user } = useSelector(state => state?.reducer);

    const handlePageClick = (data) => {
        let locCurrentPage = data.selected + 1;
        dispatch(setVehiclePageNum(locCurrentPage));
        fetchAllVehicles(locCurrentPage, vehiclePageSize);
    }


    const calculateSerialNumber = (index) => {

        const offset = (vehiclePageNum - 1) * vehiclePageSize;

        return offset + index + 1;
    };
    const tableheaders = [
        "S.NO", "Vehicle Number",  "Start Date", user?.role !== "CLT-HUB" ? "Amount" : null, user?.role !== "CLT-HUB" ? "Hub Name" : null,user?.role =="CLT" || user?.role== "CLT-RGN" || user?.role =="CLT-HUB"?null:"Actions"
        // "No. of Addon", "Actions",
    ]



    const handleChangePageSize = (pageSize) => {
        dispatch(setVehiclePageSize(pageSize));
        fetchAllVehicles(1, pageSize);
    }
    useEffect(() => {
        if (vehicleSearchText != "") {


            fetchAllVehicles(1, vehiclePageSize);
            dispatch(setVehiclePageNum(1));
        }
        else {
            fetchAllVehicles(vehiclePageNum, vehiclePageSize);
        }
    }, [vehicleSearchText])

    const fetchAllVehicles = async (pgno, pgSize) => {
        let reqBody = {
            "pageNum": pgno,
            "pageSize": pgSize,
            "search": vehicleSearchText,
        };
        if (props?.isFromClient) {
            reqBody['clientId'] = props?.clientId;
        }
        else {
            reqBody['hubId'] = props?.hubId;
        }
        let allVehicleData = await fetchData('POST', 'v1/booking/all', reqBody);
        if (allVehicleData?.success) {
            setVehicle(allVehicleData?.data?.bookings);
            setTotalVehicle(allVehicleData?.data?.totalCount);
        }
        else {
            swal.fire(
                'Something went wrong',
                '',
                'error'
            )
        }
    }
    const handleremoveVehicle = async (bookingId, vehicleId) => {

        const confirmationResult = await swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, remove it!'
        });

        if (confirmationResult.isConfirmed) {

            const removeresponse = await fetchData('POST', 'v1/booking/remove', { bookingId, vehicleId });
            if (removeresponse.success) {

                await swal.fire('Removed!', 'Your vehicle has been removed.', 'success');

                fetchAllVehicles(vehiclePageNum, vehiclePageSize);
            } else {

                await swal.fire('Error', 'Something went wrong while removing the vehicle.', 'error');
            }
        }
    };



    return (
        <div>
            <h4>Vehicles ({totalVehicle})</h4>
            <div className="row mb-4">
                <div className="col-md-4 col-xl-3">
                    <div className="position-relative">
                        <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Vehicle..." value={vehicleSearchText} onChange={(e) => {
                            dispatch(setVehicleSearchText(e.target.value));
                        }} />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                    </div>
                </div>
                <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0" style={{ gap: "10px" }}>
                    {props?.isFromHub && (user?.role == "HNC-RGN" || user?.role == "HNC") ?
                        <a href="javascript:void(0)" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addVechileModal" onClick={() => {
                            setShowModal(true);
                        }}>
                            <i className="ti ti-plus me-1 fs-5" /> Add Vehicle
                        </a>
                        :
                        null
                    }
                </div>
            </div>
            <Table
                headers={tableheaders}
                pageSize={vehiclePageSize}
                handleChangePageSize={handleChangePageSize}
                startCount={calculateSerialNumber(0)}
                endCount={totalVehicle < (vehiclePageNum * vehiclePageSize) ? totalVehicle : (vehiclePageNum * vehiclePageSize)}
                totalCount={totalVehicle}
                handlePageClick={handlePageClick}
                pageNum={vehiclePageNum}
                pageCount={(totalVehicle / vehiclePageSize)}
            >
                <tbody>
                    {vehicle?.map((item, index) => {
                        return (
                            <tr className="search-items"
                                key={item?._id}
                            >
                                {/* <td>{calculateSerialNumber(index)}</td> */}
                                <td>{index + 1}</td>
                                <td>
                                    <div className="d-flex align-items-center">

                                        <div className="me-2">
                                            <td className="user-name mb-0">{item?.vehicleId?.vehicleNum}</td>
                                        </div>
                                    </div>
                                </td>
                                <td className="usr-email-addr">{moment(item?.startDate).format("DD-MM-YYYY")}</td>
                                <td className="usr-email-addr">
                                    <div class="d-flex align-items-center me-2">
                                        <p className="mb-0 ms-2">
                                            {user?.role === "CLT-HUB" ? null : item?.bookingCost}
                                        </p>
                                    </div>
                                </td>
                                <td className="usr-email-addr">{user?.role === "CLT-HUB" ? null : item?.hubId?.name}</td>
                                {/* <td className="usr-email-addr">3/5</td> */}
                                {/* <td>
                                 <div className="dropdown dropstart">
                                     <a href="#" className="link text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                         <i className="ti ti-dots fs-7" />
                                     </a>
                                     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                         <li><p class='ps-3 pt-2 fs-3'>Mark Status</p></li>
                                         <li><p className="dropdown-item pt-1"
                                             style={{
                                                 cursor: 'pointer',
     
                                             }}
                                         >Completed</p></li>
                                         <li><p className="dropdown-item pt-1"
                                             style={{
                                                 cursor: 'pointer'
                                             }}
                                         >Incomplete</p></li>
     
                                     </ul>
                                 </div>
                             </td> */}
                                {
                                    user?.role == "HNC" || user?.role == "HNC-RGN" ?
                                        <td>
                                            <div className="dropdown dropstart pe-3 pt-2">
                                                <a
                                                    href="#"
                                                    className="link text-dark"
                                                    id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i className="ti ti-dots fs-7" />
                                                </a>
                                                <ul
                                                    className="dropdown-menu "
                                                    aria-labelledby="dropdownMenuButton"
                                                > <li>
                                                        <p
                                                            className="dropdown-item"
                                                            style={{ color: 'red', cursor: 'pointer' }}
                                                            onClick={() => handleremoveVehicle(item?._id, item?.vehicleId?._id)}
                                                        >
                                                            Remove vehicle
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        :
                                        null
                                }

                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <AddVechile
                hubId={props?.hubId}
                clientId={props?.clientId?._id}
                showModal={showModal}
                setShowModal={(val) => setShowModal(val)}
                fetchAllVehicles={fetchAllVehicles}
            />

        </div>
    )
}
