import React from 'react'
import ReactPaginate from 'react-paginate';
import '../styles/table.css';

export default function Table(props) {
    const pageSizes = [10, 20, 50, 100];
    // Access the children of the Table component
    const children = props.children;

    // Find the <tbody> element if it exists among the children
    const tbodyElement = React.Children.toArray(children).find(child => React.isValidElement(child) && child.type === 'tbody');
    return (
        <div className="card card-body">
            <div className="table-responsive" style={{
                overflowX: 'scroll'
            }}>
                <div className='tableFixHead'>
                    <table className="table search-table align-middle text-nowrap">
                        <thead className="header-item">
                            <tr>
                                {props?.headers?.map(item => (
                                     <>
                                     {item === "@"?
                                     <th>
                                         <input type='checkbox' checked={props?.selectAll} onClick={()=>{
                                             props?.handleSelectAllJobs();
                                         }}/>
                                     </th>
                                     :
                                    <th>{item}</th>
                                }
                                </>
                                ))}
                            </tr></thead>
                        {tbodyElement}
                    </table>
                </div>
            </div>
            <div className='mt-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='d-flex flex-row align-items-center'>
                            <div class="btn-group mb-2">
                                <button
                                    class="btn btn-primary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {props?.pageSize}
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    {pageSizes?.map(item => (
                                        parseInt(item) != parseInt(props?.pageSize) ?
                                            <li onClick={() => {
                                                props?.handleChangePageSize(parseInt(item))
                                            }}><p class="dropdown-item">{item}</p></li>
                                            : null
                                    ))}
                                </ul>
                            </div>
                            <p className='ms-2 mt-1'>rows per page</p>
                        </div>
                        <p>Showing <b>{props?.startCount}</b> to <b>{props?.endCount}</b> records of <b>{props?.totalCount}</b> records</p>
                    </div>
                    <div className='col-md-6 d-flex flex-row justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end'>
                        <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel="..."
                            pageCount={props?.pageCount}
                            marginPagesDisplayed={2}
                            onPageChange={props?.handlePageClick}
                            disableInitialCallback={true}
                            initialPage={props?.pageNum - 1}
                            forcePage={props?.pageNum - 1}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
