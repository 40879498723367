import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import Select from "react-select";
import { setClientPageNum } from "../../redux/nonPersistActions";
import { useForm } from "react-hook-form";
import useApiCaller from "../../hooks/useApiCaller";
import { CUSTOMER_API_URL } from "../../config";
import moment from "moment/moment";
import { carBodyTypes } from "../../constants/carBodyTypes";

export default function AddVechile(props) {
  const {
    register,
    handleSubmit,
    reset,
    setValue: vechileSetValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [vehicleBrand, setVehicleBrand] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [vehicleVarient, setVehicleVarient] = useState([])
  const [selectedVehicleBrand, setSelectedVehicleBrand] = useState([]);
  const [selectedVehicleModel, setselectedVehicleModel] = useState([]);
  const [selectedVehicleVarient, setSelectedVehicleVarient] = useState([])
  const [packageDetails, setPackageDetails] = useState([])
  const { fetchData } = useApiCaller();
  const [selectUpdateValue, setSelectUpdateValue] = useState(1);
  const { vehiclePageSize } = useSelector(state => state?.nonPersistReducer);

  useEffect(() => {
    if (props?.showModal) {
      getVehicleBrands();
      getAllPackages();
    }
  }, [props?.showModal]);

  const getVehicleBrands = async () => {
    let reqObj = {
      "type": "car",
    }
    let vehicleData = await fetchData("POST", 'v1/vehiclebrand/all', reqObj)
    if (vehicleData) {
      setVehicleBrand(vehicleData?.data?.brands?.map(item => ({ value: item?._id, label: item?.name })))
    }
  };
  const getVehicleModel = async (brandID) => {
    let reqObj = {
      "type": "car",
      "brandId": brandID
    }
    let vehicleModels = await fetchData('POST', 'v1/vehiclemodel/all', reqObj)
    if (vehicleModels) {
      setVehicleModel(vehicleModels?.data?.models?.map(item => ({ value: item?._id, label: item?.name, bodyType: item?.car_body_type })))
    }
  };
  const getVehicleVarient = async (modelId) => {
    let reqObj = {
      "type": "car",
      "modelId": modelId
    }
    let vehicleVarient = await fetchData("POST", 'v1/vehiclevariant/all', reqObj)
    if (vehicleVarient) {
      setVehicleVarient(vehicleVarient?.data?.variants?.map(item => ({ value: item?._id, label: item?.name })))
    }
  }



  const getAllPackages = async () => {
    let reqObj = {
      hubId: props?.hubId
    }
    let packageData = await fetchData("POST", 'v1/package/all', reqObj)
    if (packageData) {
      setPackageDetails(packageData?.data?.packages?.map(item => ({ value: item?._id, label: item?.name })))
    }
  }

  const handleOnCreateVechile = async (data) => {
    if (!data.vehicleBodyType || data.vehicleBodyType == "") {
      setError('vehicleBodyType', 'true');
    }
    if (!data.packageId || data.packageId == "") {
      setError('packageId', 'true');
    }
    else {
      let reqObj = {
        "vehicleNum": data?.vehicleNum,
        "vehicleType": "car",
        "clientId": props?.clientId,
        "hubId": props?.hubId,
        "startDate": moment(data?.selectedDate, "YYYY-MM-DD"),
        "servicePackageId": data?.packageId,
        "vehicleBodyType": data?.vehicleBodyType,
        "modelId": selectedVehicleModel?.value,
        "brandId": selectedVehicleBrand?.value,
        "variantId": selectedVehicleVarient?.value
      }
      let createdResponse = await fetchData(
        "POST",
        "v1/booking/add-vehicle-and-createbooking",
        reqObj
      );
      if (createdResponse?.success) {
        document.getElementById("closeAddVechileModalBtn").click();
        reset();
        setSelectedVehicleBrand({});
        setselectedVehicleModel({});
        setSelectedVehicleVarient({});
        setVehicleModel([]);
        setVehicleVarient([]);
        setSelectUpdateValue(selectUpdateValue + 1);
        props?.fetchAllVehicles(1, vehiclePageSize);
        // dispatch(setClientPageNum(1));
        swal.fire(
          "Successfully Created",
          "A new vehicle has been created successfully.",
          "success"
        );
      } else {
        swal.fire(createdResponse?.message, "", "error");
      }
    }
  };
  const getTime = () => {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const tomorrowString = tomorrow.toISOString().split("T")[0];
    return tomorrowString;
  };


  return (
    <div
      className="modal fade"
      id="addVechileModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addContactModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center">
            <h5 className="modal-title">Add New Vehicle</h5>
            <button
              type="button"
              id="closeAddVechileModalBtn"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                props?.setShowModal(false);
              }}
            />
          </div>
          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                <form
                  id="addContactModalTitle"
                  onSubmit={handleSubmit(handleOnCreateVechile)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 contact-name">
                        <label>
                          Vechile Number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("vehicleNum", {
                            pattern: "/^[A-Z]{2}[0-9]{1,2}[A-Z]{0,2}[0-9]{4}$/",
                            required: true,
                          })}
                          maxLength={10}
                          className="form-control"
                          placeholder="eg: TS01AB1234"
                        />
                        {errors.vehicleNum && (
                          <p>
                            <small className="text-danger">
                              Please valid vechile number.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 contact-phone">
                        <label>
                          Brand
                        </label>
                        <Select
                          key={`unique_${selectUpdateValue}`}
                          options={vehicleBrand}
                          value={vehicleBrand?.filter(item=>item?.value == selectedVehicleBrand?.value)}
                          onChange={(e) => {
                            getVehicleModel(e.value);
                            setSelectedVehicleBrand(e);
                            setselectedVehicleModel({});
                            setSelectedVehicleVarient({});
                            vechileSetValue('vehicleBodyType', "")
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label>Model</label>
                        <Select
                          key={`unique_${selectUpdateValue}`}
                          value={selectedVehicleModel}
                          options={vehicleModel}
                          onChange={(e) => {
                            getVehicleVarient(e.value)
                            setselectedVehicleModel(e)
                            vechileSetValue('vehicleBodyType', e.bodyType)
                            clearErrors('vehicleBodyType');
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 contact-phone">
                        <label>Varient</label>
                        <Select
                          key={`unique_${selectUpdateValue}`}
                          value={selectedVehicleVarient}
                          options={vehicleVarient}
                          onChange={(e) => {
                            setSelectedVehicleVarient(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {watch('vehicleBodyType') ?
                      <div>
                        <label>Body Type<span className="text-danger">*</span></label>
                        <h4>{watch('vehicleBodyType')}</h4>
                      </div>
                      :
                      <div className="col-md-12">
                        <div className="mb-3 contact-email">
                          <label>
                            Select Body Type<span className="text-danger">*</span>
                          </label>
                          <Select
                            key={`unique_${selectUpdateValue}`}
                            value={carBodyTypes?.filter(item => item?.value == watch('vehicleBodyType'))}
                            options={carBodyTypes}
                            onChange={(e) => {
                              vechileSetValue("vehicleBodyType", e.value);
                              clearErrors('vehicleBodyType');
                            }}
                          />
                          {errors.vehicleBodyType && (
                            <p>
                              <small className="text-danger">
                                Please select a body type.
                              </small>
                            </p>
                          )}
                        </div>
                      </div>
                    }
                    <div className="col-md-12">
                      <div className="mb-3 contact-email">
                        <label>
                          Select Package<span className="text-danger">*</span>
                        </label>
                        <Select
                          key={`unique_${selectUpdateValue}`}
                          options={packageDetails}
                          onChange={(e) => {
                            vechileSetValue("packageId", e.value)
                            clearErrors('packageId');
                          }}
                        />
                        {errors.packageId && (
                          <p>
                            <small className="text-danger">
                              Please select a package.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3 contact-location">
                        <label>Start Date<span className="text-danger">*</span></label>
                        <input
                          type="date"
                          {...register("selectedDate", {
                            required: true,
                          })}
                          min={getTime()}
                          className="form-control"
                          placeholder=" enter starting date"
                          maxlength="10"
                        />
                        {errors.selectedDate && (
                          <p>
                            <small className="text-danger">
                              Please enter date.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-success rounded-pill px-4"
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}
