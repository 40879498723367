import React, { useEffect, useState } from "react";
import useApiCaller from "../hooks/useApiCaller";
import AddPartners from "./modals/AddPartners";
import swal from "sweetalert2";
import PartnerCard from "./mini/PartnerCard";
import { useSelector } from "react-redux";
import PartnerUpdateDetails from "./modals/PartnerUpdateDetails";
import PartnerAadharImage from "./modals/PartnerAadharImage";

export default function PartnersList(props) {
    const [searchText, setSearchText] = useState("");
    const [partners, setPartners] = useState([]);
    const [finalPartners, setFinalPartners] = useState([]);
    const { fetchData } = useApiCaller();
    const user = useSelector(state => state?.reducer?.user);
    const [accountStatus, setAccountStatus] = useState("")


    useEffect(() => {
        fetchAllPartners()
    }, []);
    const fetchAllPartners = async () => {
        let reqObj = {};
        if (props?.isFromClient) {
            reqObj.clientId = props?.clientId;
        }
        else {
            reqObj.hubId = props?.hubId;
        }
        let allPartnersResp = await fetchData("POST", "v1/partner/all", reqObj);
        if (allPartnersResp?.success) {
            let localPartners = allPartnersResp?.data?.partners;
            setPartners(localPartners);
            setFinalPartners(localPartners);
        } else {
            swal.fire(
                'Something went wrong',
                '',
                'error'
            )
        }
    };

    useEffect(() => {
        if (partners?.length > 0) {
            setFinalPartners(partners?.filter(item => (new RegExp(searchText, "i")?.test(item?.name) || new RegExp(searchText, "i")?.test(item?.phone) || new RegExp(searchText, "i")?.test(item?.aadhar_num))))
        }
    }, [searchText]);

    const [selectedPartnerToUpdate, setSelectedPartnerToUpdate] = useState({});
    const handleSelectPartnerToUpdate = (partnerId, mode) => {
        setSelectedPartnerToUpdate(partners?.filter(item => item?._id == partnerId)[0]);
        if (mode == "update") {
            document.getElementById('partnerUpdateDetailsBtn').click();
        }
        else if (mode == "PartnerAadharImage") {
            document.getElementById('partnerAadharDetailsBtn').click();
        }
    }
    return (
        <div>
            <h4>Partners ({finalPartners?.length})</h4>
            <div className="row">
                <div className="col-md-4 col-xl-3">
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control product-search ps-5"
                            id="input-search"
                            placeholder="Search Partners..."
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                    </div>
                </div>
                <div
                    className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0"
                    style={{ gap: "10px" }}
                >
                    {(user?.role == "HNC" || user?.role == "HNC-RGN") && props?.isFromHub ?
                        <a
                            href="javascript:void(0)"
                            id="btn-add-contact"
                            className="btn btn-info d-flex align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#addPartnerModal"
                        >
                            <i className="ti ti-plus me-1 fs-5" /> Add Partners
                        </a>
                        :
                        null
                    }
                </div>
            </div>

            <div className="row mt-4">
                {
                    finalPartners?.length > 0 ?
                        finalPartners?.map((item, index) => {

                            return (
                                <div className="col-md-3 col-lg-4 d-flex align-items-stretch">
                                    <PartnerCard
                                        name={item?.name}
                                        phone={item?.phone}
                                        aadhar_num={item?.aadhar_num}
                                        otp={item?.otp}
                                        profileImage={item?.profileImage}
                                        accountStatus={item?.accountStatus}
                                        partnerId={item?._id}
                                        hubName={item?.hubId?.name}
                                        handleSelectPartnerToUpdate={handleSelectPartnerToUpdate}
                                        aadhar_front_image={item?.aadhar_front_image}
                                        aadhar_back_image={item?.aadhar_back_image}
                                        fetchAllPartners={fetchAllPartners}
                                    />

                                </div>
                            );
                        })
                        :
                        <h4 className='text-center text-muted'>No partners to show</h4>
                }
            </div>
            <AddPartners
                fetchAllPartners={fetchAllPartners}
                hubId={props?.hubId}
                clientId={props?.clientId}
            />
            <PartnerUpdateDetails
                fetchAllPartners={fetchAllPartners}
                partnerId={selectedPartnerToUpdate?._id}
                name={selectedPartnerToUpdate?.name}
                aadhar_num={selectedPartnerToUpdate?.aadhar_num}
            />
            <button
                id={`partnerUpdateDetailsBtn`}
                className='d-none'
                data-bs-toggle="modal"
                data-bs-target={`#partnerUpdateDetails`}>
            </button>
            <PartnerAadharImage
                fetchAllPartners={fetchAllPartners}
                partnerId={selectedPartnerToUpdate?.partner_Id}
                aadhar_front_image={selectedPartnerToUpdate?.aadhar_front_image}
                aadhar_back_image={selectedPartnerToUpdate?.aadhar_back_image}

            />
            <button
                id={`partnerAadharDetailsBtn`}
                className='d-none'
                data-bs-toggle="modal"
                data-bs-target={`#partnerAdharDetails`}>
            </button>

        </div>
    );
}
