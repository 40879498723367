import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './reducers/rootReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['nonPersistReducer']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    // devTools: process.env.SYSTEM_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)