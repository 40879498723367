import { useState } from "react";
import useApiCaller from "./useApiCaller";
import { CUSTOMER_API_URL } from "../config";
import swal from 'sweetalert2';

const useUploadImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { fetchDataFromUrl } = useApiCaller();

    const uploadImage = async (e) => {
        setIsLoading(true);
        if (e.target.files && e.target.files[0]) {
            var formdata = new FormData()
            formdata.append("file", e.target.files[0], e.target.files[0].name)
            const logoResponse = await fetchDataFromUrl('post', `${CUSTOMER_API_URL}s3/upload-compressed`, formdata, false)
            setIsLoading(false);
            return logoResponse;
        }
        else {
            swal.fire(
                'Something went wrong',
                '',
                'error'
            )
        }
    }

    return { isLoading, uploadImage };
};

export default useUploadImage;