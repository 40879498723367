import React, { useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import useApiCaller from '../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setAddonJobsPageNum, setAddonJobsPageSize, setAddonJobsSearchText } from '../redux/nonPersistActions';
import Table from '../components/Table';
import _ from 'lodash';
import moment from 'moment';
import JobFilters from './modals/JobFilters';
import AddonCompletedJobs from './mini/AddonCompletedJobs';
import AddonIncompleteJobs from './mini/AddonIncompleteJobs';
import AddonNotAttempted from './mini/AddonNotAttempted';

import AddonApproval from './mini/AddonApproval';

const Completed = lazy(() => import("./mini/AddonCompletedJobs"));
const NotAttempted = lazy(() => import("./mini/AddonIncompleteJobs"));
const ApprovalPending = lazy(() => import("./mini/AddonNotAttempted"));
const NotCleaned = lazy(() => import("./mini/AddonIncompleteJobs"));


const AddonJobsList = (props) => {
    const [activeTab, setActiveTab] = useState('Not Attempted');
    const[totalvalue,setTotalValue]=useState(0)
     
    const [tabs,setTabs] = useState([
       
        {
            label: "Not Attempted",
            count:0
           
        },
        {
            label: "Completed",
            count:0
           
        },

        {
            label: "Approval Pending",
            count:0
          
        },
        {
            label: "Not Cleaned",
            count:0
        },


    ]);
    const fetchJobCounts = async (clientId) => {
        try {
            let reqBody={
              clientId:clientId
            }
              if (props?.isFromClient) {
                reqBody['clientId'] = props?.clientId;
            }
            else {
              reqBody['hubId'] = props?.hubId;
          }
              const allJobsResp = await fetchData('POST', 'v1/addonjobs/stats', reqBody);
    
          if (allJobsResp.success) {
            const { completed, incomplete, not_attempted, not_approved } = allJobsResp.data;
    
            setTabs((prevTabs) => [
              {
                label: 'Not Attempted',
                count: not_attempted,
              },
              {
                label: 'Completed',
                count: completed,
              },
              {
                label: 'Approval Pending',
                count: not_approved,
              },
              {
                label: 'Not Cleaned',
                count: incomplete,
              },
            ]);
            setTotalValue(allJobsResp.data?.total);
          } else {
            console.error('API response error:', allJobsResp.message);
          }
        } catch (error) {
          console.error('Error fetching job counts', error);
        }
      };
    
      useEffect(() => {
        fetchJobCounts();
      }, [props?.clientId, props?.hubId]);
    
    const renderSection = () => {
        switch (activeTab) {
          
            case "Not Attempted":
                return <AddonNotAttempted
                isFromClient={props?.isFromClient}
                isFromHub={props?.isFromHub}
                hubId={props?.hubId}
                clientId={props?.clientId}
                tabs={tabs}
                setTabs={setTabs}
                />;
                case "Completed":
                    return <AddonCompletedJobs
                    isFromClient={props?.isFromClient}
                    isFromHub={props?.isFromHub}
                    hubId={props?.hubId}
                    clientId={props?.clientId}
                    tabs={tabs}
                    setTabs={setTabs}
                    />;
            case "Approval Pending":
                return <AddonApproval
                isFromClient={props?.isFromClient}
                isFromHub={props?.isFromHub}
                hubId={props?.hubId}
                clientId={props?.clientId}
                tabs={tabs}
                setTabs={setTabs}
                />;
            case "Not Cleaned":
                return <AddonIncompleteJobs
                isFromClient={props?.isFromClient}
                isFromHub={props?.isFromHub}
                hubId={props?.hubId}
                clientId={props?.clientId}
                tabs={tabs}
                setTabs={setTabs}
                />;

            default:
                return null;
        }
    }

    const [addonJobs, setAddonJobs] = useState([]);
    const [totalAddonJobs, setTotalAddonJobs] = useState(0);
    const [filters, setFilters] = useState({});
    const [isFiltersApplied, setIsFiltersApplied] = useState(false);

    const { fetchData } = useApiCaller();
    const { addonJobsPageNum, addonJobsPageSize, addonJobsSearchText } = useSelector(state => state?.nonPersistReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state?.reducer?.user);

    const fetchAllAddonJobs = async (pgno, pgSize, filters = {}) => {
        let reqBody = {
            "pageNum": pgno,
            "pageSize": pgSize,
            "search": addonJobsSearchText,
            ...filters
        }
        if (!_.isEmpty(filters)) {
            setIsFiltersApplied(true);
        }
        else {
            setIsFiltersApplied(false);
        }
        if (props?.isFromClient) {
            reqBody['clientId'] = props?.clientId;
        }
        else {
            reqBody['hubId'] = props?.hubId;
        }
        let allAddonJobsResp = await fetchData('POST', 'v1/addonjobs/all', reqBody);
        if (allAddonJobsResp?.success) {
            setAddonJobs(allAddonJobsResp?.data?.sptasks);
            setTotalAddonJobs(allAddonJobsResp?.data?.totalCount);
        }
        else {
            swal.fire(
                'Something went wrong',
                '',
                'error'
            )
        }
    }

    const delayedQuery = useCallback(_.debounce(() => fetchAllAddonJobs(1, addonJobsPageSize, filters), 1000), []);

    useEffect(() => {
        if (addonJobsSearchText != "") {
            // delayedQuery();
            fetchAllAddonJobs(1, addonJobsPageSize, filters);
            //dispatch(setAddonJobsPageNum(1));
        }
        else {
            //fetchAllAddonJobs(addonJobsPageNum, addonJobsPageSize, filters);
        }
    }, [addonJobsSearchText])

 
  

   
    const handleChangePageSize = (pageSize) => {
        //dispatch(setAddonJobsPageSize(pageSize));
        //fetchAllAddonJobs(1, pageSize, filters);
        //dispatch(setAddonJobsPageNum(1));
    }




    const handleClearFilters = () => {
        setFilters({
            fromDate: null,
            toDate: null
        })
        //fetchAllAddonJobs(1, addonJobsPageSize, {});
        //dispatch(setAddonJobsPageNum(1));
    }
  
 

   

    return (
        <div className="container-fluid">
            <div className="widget-content searchable-container list">
           
                <h4>Addon Jobs</h4>
                <div className='card'>
    <div className='card-body'>
        <div className='row d-flex'>
            <div className='col-lg'>
            {/* style={{borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)",  padding: "8px", flexDirection: "column", backgroundColor: "rgb(1, 129, 227)",marginRight: "20px", marginBottom: "24px"}} */}
    <div  style={{borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)",  padding: "8px", flexDirection: "column", backgroundColor: "rgb(1, 129, 227)",marginRight: "20px", marginBottom: "24px"}}>
        <span class="d-block" style={{fontWeight: "bold", textAlign: "center", flex: "1 1 0%",fontSize:"16px"}}>Total</span>
        <span class="d-block" style={{fontWeight: "bold", fontSize: "18px", textAlign: "center", flex: "1 1 0%"}}>  {totalvalue ? totalvalue : 0}</span>
    </div>
    </div>
    <div className='col-lg'>
    <div style={{ borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)", padding: "8px", flexDirection: "column", backgroundColor: "#808080", marginRight: "20px", marginBottom: "24px" }}>
        <span className="d-block" style={{ fontWeight: "bold", textAlign: "center", flex: "1 1 0%",fontSize:"16px" }}>Not-Attempted</span>
        <span className="d-block" style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center", flex: "1 1 0%" }}>
            {tabs.find(tab => tab.label === 'Not Attempted')?.count ?? 0}
        </span>
    </div>
</div>

    <div className='col-lg'>
    <div  style={{borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)",  padding: "8px", flexDirection: "column", backgroundColor: "#00cc44",marginRight: "20px", marginBottom: "24px"}}>
        <span class="d-block" style={{fontWeight: "bold", textAlign: "center", flex: "1 1 0%",fontSize:"16px"}}>Completed</span>
        <span className="d-block" style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center", flex: "1 1 0%" }}>
            {tabs.find(tab => tab.label === 'Completed')?.count ?? 0}
        </span>

    </div>
    </div>
    <div className='col-lg'>
    <div  style={{borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)",  padding: "8px", flexDirection: "column", backgroundColor: "rgb(255, 204, 0)",marginRight: "20px", marginBottom: "24px"}}>
        <span class="d-block" style={{fontWeight: "bold", textAlign: "center", flex: "1 1 0%",fontSize:"16px"}}>ApprovalPending</span>
        <span className="d-block" style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center", flex: "1 1 0%" }}>
            {tabs.find(tab => tab.label === 'Approval Pending')?.count ?? 0}
        </span>
    </div>
    </div>
    <div className='col-lg'>
    <div  style={{borderRadius: "8px", display: "flex", color: "rgb(255, 255, 255)",  padding: "8px", flexDirection: "column", backgroundColor: "rgb(230, 46, 0)",marginRight: "20px", marginBottom: "24px"}}>
        <span class="d-block" style={{fontWeight: "bold", textAlign: "center", flex: "1 1 0%",fontSize:"16px"}}>Incomplete</span>
        <span className="d-block" style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center", flex: "1 1 0%" }}>
            {tabs.find(tab => tab.label === 'Not Cleaned')?.count ?? 0}
        </span>
    </div>
    </div>
    
    </div>
    </div>
</div>
            
                {/* <ul className="nav nav-underline" id="myTab" role="tablist">
                            {tabs?.map(item => (
                                <li className="nav-item px-4" key={item?.label} style={{
                                    cursor: 'pointer'
                                }} >
                                    <a className={`nav-link d-flex ${activeTab == item?.label ? "active" : ""}`} onClick={() => {
                                        setActiveTab(item?.label)
                                    }} data-bs-toggle="tab" role="tab">
                                        <span><i className={`${item?.icon} fs-4`} />
                                        </span>
                                        <span className="d-none d-md-block ms-2">{item?.label} ({item?.count})</span>
                                    </a>
                                </li>
                            ))}
                        </ul> */}
                        <ul className="nav nav-underline" id="myTab" role="tablist">
          {tabs?.map((item) => (
            <li className="nav-item px-4" key={item?.label} style={{ cursor: 'pointer' }}>
              <a
                className={`nav-link d-flex ${activeTab === item?.label ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab(item?.label);
                }}
                data-bs-toggle="tab"
                role="tab"
              >
                <span>{item?.label}</span>
                <span className="d-none d-md-block ms-2">
                  ({item?.count})
                </span>
              </a>
            </li>
          ))}
        </ul>
                {!_.isEmpty(filters) && isFiltersApplied ?
                    <div className='md-4'>
                        <div>
                            <label>Applied Filters</label>
                            <div className=''>
                                {Object.keys(filters)?.map((item, index) => (
                                    <p
                                        key={item}
                                        class={`mb-1 ${index > 0 ? "ms-2" : ''} badge text-dark rounded-pill border border-primary`}
                                    >
                                        {item == "fromDate" || item == "toDate" ? moment(filters[item]).format('DD-MM-YYYY') : filters[item]}
                                    </p>
                                ))}
                                <p
                                    class='mb-1 ms-2 badge text-dark rounded-pill bg-danger text-white'
                                    onClick={() => {
                                        handleClearFilters()
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <i className='ti ti-x'
                                        style={{
                                            color: 'white',
                                            cursor: 'pointer'
                                        }}
                                    ></i> Clear filters
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }


                <div className="tab-content">
                    <div className="tab-pane active" role="tabpanel">
                        <Suspense
                            fallback={
                                <div>
                                    <h4>Loading ... </h4>
                                </div>
                            }
                        >
                            {renderSection()}
                        </Suspense>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddonJobsList



