import React from 'react'
import { useForm, useWatch } from 'react-hook-form';
import useApiCaller from '../../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useEffect, useState } from 'react';

const UpdateDetails = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { fetchData } = useApiCaller();
  const [isLoading, setIsLoading] = useState(false);



  const handleOnUpdatedetails = async (data) => {
  
    const confirmationResult = await swal.fire({
      title: 'Are you ready to update?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (confirmationResult.isConfirmed) {
      let reqBody = {
        "name": data.name,
        "phone": data.phone,
      };
      setIsLoading(true); 
      try {
        let Updatedetails = await props?.updateManager(props?.managerId, reqBody);

        if (Updatedetails?.success) {
          document.getElementById(`clsMUBtn${props?.managerId}`).click();
          await swal.fire('Success', 'Details updated successfully!', 'success');
        } else {
          swal.fire('Something went wrong', '', 'error');
        }
      } catch (error) {
        console.error("Error:", error);
        swal.fire('Error occurred', 'Please try again later.', 'error');
      } finally {
        setIsLoading(false); // Set loading back to false after the update is done
      }
    } else {
      setIsLoading(false); // Set loading back to false if the user cancels
    }
  };



  useEffect(() => {
    setValue('name', props.name || '');
    setValue('phone', props.phone || '');
  }, [props.name, props.phone]);


  return (
    <div>
      <div
        className="modal fade"
        id={`UpdateDetails`}
        tabIndex="-1"
        aria-labelledby="UpdateDetails"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5>Update Details</h5>
              <button type="button" id={`clsMUBtn${props?.managerId}`} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
            {isLoading ? (
          <p>Updating the detials,please wait ....
          </p>
        ) : (
              <form id="addContactModalTitle" onSubmit={handleSubmit(handleOnUpdatedetails)}>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 contact-name">
                      <label> <img src="./images/Partnericon.png" width="17" className='mb-1'></img>Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        {...register('name', { required: true })}
                        className="form-control"
                        value={watch('name')}
                        placeholder="Enter name"
                      />
                      {errors.name && <p><small className='text-danger'>Please enter name.</small></p>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-12">
                    <div className="mb-3 contact-email">
                      <label><img src="./images/Phoneicon.png" width="14" className='mb-1' ></img> Phone<span className='text-danger'>*</span></label>
                      <input
                        type="tel"
                        {...register('phone', {
                          pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/i,
                          required: true,
                          validate: (value) => {
                            if (!/^\d+$/.test(value)) {
                              return 'Please enter only digits for the phone number';
                            }
                            return true;
                          },
                        })}
                        maxLength="10"
                        className="form-control"
                        placeholder="Enter phone number"
                        value={watch('phone')}
                      />

                      {errors.phone && <p className="error-message">{errors.phone.message}</p>}


                      {errors.phone && <p><small className='text-danger'>Please enter a valid mobile number.</small></p>}

                    </div>
                  </div>
                </div>
                <input type="submit" className="btn btn-success px-4" />


              </form>
        )}


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateDetails