import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApiCaller from '../../hooks/useApiCaller';
import swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setAddonCompletedJobsPageNum, setAddonCompletedJobsPageSize, setAddonCompletedJobsSearchText } from '../../redux/nonPersistActions';
import Table from '../../components/Table';
import _ from 'lodash';
import moment from 'moment';
import JobFilters from '../modals/JobFilters';
import "../../styles/JobsList.css";
import { HUB_PARTNER_API_URL } from '../../config';

const AddonCompletedJobs = (props) => {
    const [addonJobs, setAddonJobs] = useState([]);
    const [totalAddonJobs, setTotalAddonJobs] = useState(0);
    const [filters, setFilters] = useState({});
    const [isFiltersApplied, setIsFiltersApplied] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { fetchData,fetchDataFromUrl } = useApiCaller();
    const { addoncompletedJobsPageNum, addoncompletedJobsPageSize,  addoncompletedJobsSearchText } = useSelector(state => state?.nonPersistReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state?.reducer?.user);

    const fetchAllAddonJobs = async (pgno, pgSize, filters = {}) => {
        let reqBody = {
            "pageNum": pgno,
            "pageSize": pgSize,
            "search": addoncompletedJobsSearchText,
            "status":"Completed",
            ...filters
        }
        if (!_.isEmpty(filters)) {
            setIsFiltersApplied(true);
        }
        else {
            setIsFiltersApplied(false);
        }
        if (props?.isFromClient) {
            reqBody['clientId'] = props?.clientId;
        }
        else {
            reqBody['hubId'] = props?.hubId;
        }
        let allAddonJobsResp = await fetchData('POST', 'v1/addonjobs/all', reqBody);
        if (allAddonJobsResp?.success) {
            setAddonJobs(allAddonJobsResp?.data?.sptasks);
            setTotalAddonJobs(allAddonJobsResp?.data?.totalCount);
            // props?.setTabs(props?.tabs?.map(item=>{
            //     if(item?.label == "Completed"){
            //         item.count = allAddonJobsResp?.data?.totalCount;
            //     }
            //     return item;
            // }))
        }
        else {
            swal.fire(
                'Something went wrong',
                '',
                'error'
            )
        }
    }

    const delayedQuery = useCallback(_.debounce(() => fetchAllAddonJobs(1, addoncompletedJobsPageSize, filters), 1000), []);

    useEffect(() => {
        if (addoncompletedJobsSearchText != "") {
            // delayedQuery();
            fetchAllAddonJobs(1, addoncompletedJobsPageSize, filters);
            dispatch(setAddonCompletedJobsPageNum(1));
        }
        else {
            fetchAllAddonJobs(addoncompletedJobsPageNum, addoncompletedJobsPageSize, filters);
        }
    }, [addoncompletedJobsSearchText])

    const handlePageClick = (data) => {
        let locCurrentPage = data.selected + 1;
        dispatch(setAddonCompletedJobsPageNum(locCurrentPage));
        fetchAllAddonJobs(locCurrentPage, addoncompletedJobsPageSize, filters);
    }
    const calculateSerialNumber = (index) => {

        const offset = (addoncompletedJobsPageNum - 1) * addoncompletedJobsPageSize;

        return offset + index + 1;
    };

    // "S.NO", "Vechile Number", "Vechile Details", "Date", "Status", "Hub Name", "Actions"


    const tableheaders = [
        "S.NO", (props?.isFromClient && user?.role !== "CLT-HUB" ? "Hub" : null), "Date", "Vehicle Number", "Approve",user?.role == "CLT" || user?.role == "CLT-RGN" || user?.role == "CLT-HUB"?null:"Actions"
    ]

    const handleChangePageSize = (pageSize) => {
        dispatch(setAddonCompletedJobsPageSize(pageSize));
        fetchAllAddonJobs(1, pageSize, filters);
        dispatch(setAddonCompletedJobsPageNum(1));
    }

    const handleApplyFilters = () => {
        fetchAllAddonJobs(1, addoncompletedJobsPageSize, filters);
        dispatch(setAddonCompletedJobsPageNum(1));
    }

    const handleClearFilters = () => {
        setFilters({
            fromDate: null,
            toDate: null
        })
        fetchAllAddonJobs(1, addoncompletedJobsPageSize, {});
        dispatch(setAddonCompletedJobsPageNum(1));
    }
    const handleCompleteAddonJob = (jobId) => {
        swal.fire({
            title: `Are you sure?`,
            text: `Do you want to mark this job as completed.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12DEB9',
            cancelButtonColor: 'red',
            confirmButtonText: `Mark as Completed`,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let statusUpdateRes = await fetchData('POST', `v1/addonjobs/complete/${jobId}`);
                if (statusUpdateRes?.success) {
                    swal.fire('Marked Completed!', "This job has been marked as completed.", "success");
                    fetchAllAddonJobs(addoncompletedJobsPageNum, addoncompletedJobsPageSize, filters);
                    let reqBody={
                        clientId:props?.clientId
                    }
                  let fetchCompletedCount = await fetchData('POST', 'v1/job/stats', reqBody);

if (fetchCompletedCount?.success) {
    
    props?.setTabs(props?.tabs?.map(item => {
        if (item?.label === "Completed") {
            item.count = fetchCompletedCount?.data?.completed;
        }
        return item;
    }))
    props?.setTabs(props?.tabs?.map(item => {
        if (item?.label === "Not Cleaned") {
            item.count = fetchCompletedCount?.data?.incomplete;
        }
        return item;
    }))
   
} else {
    swal.fire(
        'Something went wrong',
        '',
        'error'
    )
}

                    
                
            
                }
                else {
                    swal.fire("Something went wrong!", statusUpdateRes?.message, 'warning');
                }
            }
        });
    }
    const handleInCompleteAddonJob = (jobId) => {
        swal.fire({
            title: `Are you sure?`,
            text: `Do you want to mark this job as Incomplete.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FA896B',
            cancelButtonColor: 'red',
            confirmButtonText: `Mark as Incomplete`,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Fetch reasons from the API
                const reasonsResponse = await fetchDataFromUrl('post', `${HUB_PARTNER_API_URL}v1/jobs/reason`, {}, false);
    
                if (reasonsResponse?.success) {
                    const radioOptions = reasonsResponse.data;
    
                    // Display a modal with radio buttons
                    const { value: selectedReasonIndex } = await swal.fire({
                        title: 'Select a reason',
                        input: 'radio',
                        inputOptions: radioOptions,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: 'red',
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        customClass: {
                            title: 'custom-title',
                            input: 'vertical-radio-buttons',
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button'
                        },
    
                    });
    
    
                    if (selectedReasonIndex !== undefined) {
                        const selectedReason = radioOptions[selectedReasonIndex];
    
                        let reqBody = {
                            reason: selectedReason,
                        };
    
                        let statusUpdateRes = await fetchData('POST', `v1/job/incomplete/${jobId}`, reqBody);
                        if (statusUpdateRes?.success) {
                            swal.fire('Marked as Incomplete!', 'This job has been marked as incomplete.', 'success');
                            fetchAllAddonJobs(addoncompletedJobsPageNum, addoncompletedJobsPageSize, filters);
                            let reqBody={}
                    if (props?.isFromClient) {
                        reqBody['clientId'] = props?.clientId;
                    }
                    else {
                        reqBody['hubId'] = props?.hubId;
                    }
                    let fetchCompletedCount = await fetchData('POST', 'v1/addonjobs/stats', reqBody);
    
                    if (fetchCompletedCount?.success) {
                    
                    props?.setTabs(props?.tabs?.map(item => {
                        if (item?.label === "Approval Pending") {
                            item.count = fetchCompletedCount?.data?.not_approved;
                        }
                        return item;
                    }))
                    props?.setTabs(props?.tabs?.map(item => {
                        if (item?.label === "Completed") {
                            item.count = fetchCompletedCount?.data?.completed;
                        }
                        return item;
                    }))
                    
                    } else {
                    swal.fire(
                        'Something went wrong',
                        '',
                        'error'
                    )
                    }
                        } else {
                            swal.fire('Something went wrong!', statusUpdateRes?.message, 'warning');
                        }
                    }
                } else {
                    swal.fire('Something went wrong!', 'Failed to fetch reasons.', 'error');
                }
            }
        });
    }
    useEffect(() => {
        getAllReasons();
    }, [])
    const getAllReasons = async () => {
        let reasonsResponse = await fetchDataFromUrl('post', `${HUB_PARTNER_API_URL}v1/jobs/reason`, {}, false);
        if (reasonsResponse?.success) {
            setReasons(reasonsResponse?.data);
        }
        else {
            swal?.fire('Warning!', 'something went wrong.', 'warning');
        }
    }
    const handleApproveAddonJob = (jobId) => {
        swal.fire({
            title: `Are you sure?`,
            text: `Do you want to mark this job as Approved.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12DEB9',
            cancelButtonColor: 'red',
            confirmButtonText: `Approve`,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let statusUpdateRes = await fetchData('POST', `v1/addonjobs/approve/${jobId}`);
                if (statusUpdateRes?.success) {
                    swal.fire('Approved!', "This job has been approved.", "success");
                    fetchAllAddonJobs(addoncompletedJobsPageNum, addoncompletedJobsPageSize, filters);
                    let reqBody={}
                    if (props?.isFromClient) {
                        reqBody['clientId'] = props?.clientId;
                    }
                    else {
                        reqBody['hubId'] = props?.hubId;
                    }
                  let fetchCompletedCount = await fetchData('POST', 'v1/addonjobs/stats', reqBody);
    
    if (fetchCompletedCount?.success) {
    
    props?.setTabs(props?.tabs?.map(item => {
        if (item?.label === "Approval Pending") {
            item.count = fetchCompletedCount?.data?.not_approved;
        }
        return item;
    }))
    props?.setTabs(props?.tabs?.map(item => {
        if (item?.label === "Completed") {
            item.count = fetchCompletedCount?.data?.completed;
        }
        return item;
    }))
    
    } else {
    swal.fire(
        'Something went wrong',
        '',
        'error'
    )
    }
                }
                else {
                    swal.fire("Something went wrong!", statusUpdateRes?.message, 'warning');
                }
            }
        });
    }

    return (
       
        <div className="container-fluid">
           <div className="row mb-4 mt-4">
                    <div className="col-md-4 col-xl-3">
                        <form className="position-relative">
                            <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Jobs..." value={addoncompletedJobsSearchText} onChange={(e) => {
                                dispatch(setAddonCompletedJobsSearchText(e.target.value));
                            }} />
                            <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                        </form>
                    </div>
                    <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0" style={{ gap: "10px" }}>
                        <a href="javascript:void(0)" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#filterJobsModal">
                            <i className="ti ti-filter text-white me-1 fs-5" /> Filters
                        </a>
                    </div>
                </div>
               
              
                {!_.isEmpty(filters) && isFiltersApplied ?
                    <div className='md-4'>
                        <div>
                            <label>Applied Filters</label>
                            <div className=''>
                                {Object.keys(filters)?.map((item, index) => (
                                    <p
                                        key={item}
                                        class={`mb-1 ${index > 0 ? "ms-2" : ''} badge text-dark rounded-pill border border-primary`}
                                    >
                                        {item == "fromDate" || item == "toDate" ? moment(filters[item]).format('DD-MM-YYYY') : filters[item]}
                                    </p>
                                ))}
                                <p
                                    class='mb-1 ms-2 badge text-dark rounded-pill bg-danger text-white'
                                    onClick={() => {
                                        handleClearFilters()
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <i className='ti ti-x'
                                        style={{
                                            color: 'white',
                                            cursor: 'pointer'
                                        }}
                                    ></i> Clear filters
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Table
                    headers={tableheaders}
                    pageSize={addoncompletedJobsPageSize}
                    handleChangePageSize={handleChangePageSize}
                    startCount={calculateSerialNumber(0)}
                    endCount={totalAddonJobs < (addoncompletedJobsPageNum* addoncompletedJobsPageSize) ? totalAddonJobs : (addoncompletedJobsPageNum * addoncompletedJobsPageSize)}
                    totalCount={totalAddonJobs}
                    handlePageClick={handlePageClick}
                    pageNum={addoncompletedJobsPageNum}
                    pageCount={(totalAddonJobs / addoncompletedJobsPageSize)}
                >
                    <tbody>
                        {addonJobs?.map((item, index) => (
                            <tr className="search-items" key={item._id} >
                                <td>{calculateSerialNumber(index)}</td>
                                <td>
                                    </td>
                                <td>{moment(item.dueDate).format("DD-MM-YYYY")}</td>
                                <td>{item.vehicleNum}</td>
                                {/* <td>
                                <span
                                        className={`badge ${item?.status == "Completed" ? "bg-success" : ""}`}
                                    >
                                        {item?.status}
                                    </span>
                                </td> */}
                            
                                {(user?.role == "CLT" || user?.role == "CLT-RGN" || user?.role == "CLT-HUB") ?
                                               
                                                    <td
                                                   
                                                        onClick={() => {
                                                            if (!item.isApproved) {
                                                                handleApproveAddonJob(item?._id);
                                                            }
                                                            else {
                                                                swal.fire('Approved!', "Job already approved.", 'warning');
                                                            }
                                                        }}
                                                    >
                                                     <button className={`btn ${item.isApproved ? 'btn-success' : 'btn-primary'}`}>
        {item.isApproved ? 'Approved' : 'Not Approved'}
    </button>
                                                    </td>
                                               
                                                :    <td style={{ color: item.isApproved ? 'green' : 'red' }}>
                                                {item.isApproved ? 'Approved' : 'Not Approved'}
                                            </td>}
                                <td>
                                    <div className="dropdown dropstart pe-3 pt-2">
                                        <a
                                            href="#"
                                            className="link text-dark"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="ti ti-dots fs-7" />
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                           
                                            {(user?.role == "HNC" || user?.role == "HNC-RGN") ?
                                                <>
                                                    {/* <li>
                                                        <p
                                                            className="dropdown-item"
                                                            style={{ color: 'green', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleCompleteAddonJob(item?._id)
                                                            }}
                                                        >
                                                            Mark Complete
                                                        </p>
                                                    </li> */}
                                                    <li><p className="dropdown-item"
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            handleInCompleteAddonJob(item?._id)
                                                        }}
                                                    >
                                                        Mark Incomplete
                                                    </p>
                                                    </li>
                                                </>
                                                :
                                                null
                                            }
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
        
            <JobFilters
                handleApplyFilters={handleApplyFilters}
                filters={filters}
                setFilters={setFilters}
            />
        </div>
    );
}

export default AddonCompletedJobs