import React from 'react'
import { Link } from 'react-router-dom'
import Clientdetails from './modals/Clientdetails'
import { useSelector } from 'react-redux'

export default function PageBreadCrumb(props) {
    const user = useSelector(state => (state?.reducer?.user))
    return (
        <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                    <div className="col-9">
                        <div className='d-flex flex-row align-items-center'>
                        <h4 className="fw-semibold mb-0">{props?.pageTitle}</h4>
                        {props.showButton && (user?.role == "HNC" || user?.role == "HNC-RGN") && <a href="javascript:void(0)" id="btn-add-contact" className=" d-flex align-items-center " style={{ width: "14%", color: "black" }} data-bs-toggle="modal" data-bs-target="#Clientdetails">
                        <i className='fa fa-eye' style={{ color: "#000", fontSize:12,marginLeft:4,marginTop:4 }}></i> 
                        </a>}
                        </div>
                        {/* {props?.phonedetails ?
                            <>
                                <small><b>Phone:</b>{props?.phonedetails}</small><br></br>
                            </>
                            :
                            null
                        }
                        {props?.emaildetails ?
                            <>
                                <small><b>Email:</b>{props?.emaildetails}</small><br></br>
                            </>
                            :
                            null
                        }
                        {props?.address ?
                            <>
                                <small><b>Address:</b> {props?.address}</small><br></br>
                            </>
                            :
                            null
                        }
                        {props?.gstin ?
                            <>
                                <small><b>GST:</b> {props?.gstin}</small><br></br>
                            </>
                            :
                            null
                        }
                        {props?.joiningDate ?
                            <>
                                <small><b>Joined on:</b> {props?.joiningDate}</small>
                            </>
                            :
                            null
                        } */}

                        {/* {props.showButton && <a href="javascript:void(0)" id="btn-add-contact" className=" d-flex align-items-center " style={{ width: "14%", color: "black" }} data-bs-toggle="modal" data-bs-target="#Clientdetails">
                        <p style={{ color: "#1a75ff" }}><i className='fa fa-eye'></i> View more..</p>
                        </a>} */}
                        <small>{props?.name}</small>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {props?.breadCrumbs?.map(item => (
                                    <li className="breadcrumb-item mt-0">
                                        {item?.link == '#' ?
                                            <span>{item?.label}</span>
                                            :
                                            <Link to={item?.link} className="text-muted ">{item?.label}</Link>
                                        }
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                    <div className="col-3">
                        <div className="text-center ">
                            <img src={props?.logo} height={70} ></img>
                            {/* <img src="../../dist/images/breadcrumb/ChatBc.png" alt="" className="img-fluid mb-n4" /> */}
                        </div>
                    </div>
                </div>
            </div>
            {props.showButton ?
                <Clientdetails />
                :
                null
            }
        </div>
    )
}
