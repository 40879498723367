import React, { useState, useEffect } from 'react';
import useApiCaller from '../../hooks/useApiCaller';

import moment from 'moment';

const Clientdetails = () => {
  const [clientDetails, setClientDetails] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const clientId = queryParams.get('id');
  const { fetchData } = useApiCaller();

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    try {
      const response = await fetchData('get', `v1/client/get/${clientId}`);
      if (response?.success) {
        setClientDetails(response?.data);
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };

  return (
    <div>
      <div className="modal fade" id={`Clientdetails`} tabIndex="-1" aria-labelledby="ChangePassword" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5>Client Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                
                  <div className="mb-3 d-flex justify-content-center p-4" style={{flexDirection:'column'}}>
                  <h3 > {clientDetails?.name}</h3>
                    {/* <h6><b className='fw-bold'>Phone:</b> <span className='fw-semibold'>{clientDetails?.phone}</span></h6>
                    <h6><b className='fw-semibold'>Email:</b>{clientDetails?.email}</h6>
                   <h6><b className='fw-semibold'> Address:</b>{clientDetails?.address}</h6>
                    <p>JoiningDate:{clientDetails?.joiningDate}</p>
                    <p>GSTIN:{clientDetails?.gstin}</p> */}
                    {/* Add other properties from clientDetails */}
                    <div className='row' >
                     <div className='d-flex ' >
                     {clientDetails?.phone?
                      <>
                        <div className='col-lg-3'>
                           <h6><b>Phone:</b></h6>
                           </div>
                           <div className='col-lg-9'>
                    
                            <h6 className='fw-semibold'>{clientDetails?.phone}</h6>
                            </div>
                            </>
                            :
                            null
                            }
                            </div>
                    </div>
                    <div className='row' >
                     <div className='d-flex ' >
                     {clientDetails?.email?
                      <>
                        <div className='col-lg-3'>
                           <h6><b>Email:</b></h6>
                           </div>
                           <div className='col-lg-9'>
                       
                            <h6 className='fw-semibold'>{clientDetails?.email}</h6>
                            </div>
                            </>
                             :
                             null
                             }

                            </div>
                    </div>
                    <div className='row' >
                     <div className='d-flex '>
                      {clientDetails?.address?
                      <>
                        <div className='col-lg-3'>
                         
                           <h6><b>Address:</b></h6>
                           </div>
                           <div className='col-lg-9'>
                       
                            <h6 className='fw-semibold'>{clientDetails?.address}</h6>
                            </div>
                            </>
                            :
                            null
                            }
                            </div>
                          
                    </div>
                    <div className='row' >
                     <div className='d-flex ' >
                     {clientDetails?.createdAt?
                      <>
                        <div className='col-lg-3'>
                      
                       <h6><b>JoiningDate:</b></h6>
                       
                           </div>
                           <div className='col-lg-8'>
                       
                            <h6 className='fw-semibold'>{clientDetails?.createdAt ? moment(clientDetails?.createdAt).format('DD MMM, YYYY - LT') : ''}</h6>
                            </div>
                            </>
                            :
                            null
}
                            </div>
                    </div>
                    <div className='row' >
                     <div className='d-flex ' >
                     {clientDetails?.gstin?
                      <>
                        <div className='col-lg-3'>
                     
                           <h6><b>GSTIN:</b></h6>
                           </div>
                           <div className='col-lg-9'>
                       
                            <h6 className='fw-semibold'>{clientDetails?.gstin}</h6>
                            </div>
                            </> :
                            null
                            }
                            </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clientdetails;
