import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset, setUser } from '../redux/actions';
import Sidebar from '../components/Sidebar';
import { simpleAction } from '../redux/nonPersistActions';
import { useNavigate } from 'react-router-dom';
import useApiCaller from '../hooks/useApiCaller';

export default function Dashboard() {
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const name = useSelector(state => (state?.nonPersistReducer?.name))
    const user = useSelector(state => (state?.reducer?.user))
    const { fetchData, isLoading } = useApiCaller();
    const [apiData, setApiData] = useState({});

    useEffect(() => {
        (async () => {
            let data = await fetchData('post', 'v1/client/create');
            setApiData(data);
        })()
    }, [])


    useEffect(() => {
        // dispatch(reset());
        // dispatch(simpleAction("teja"));
        // dispatch(setUser({
        //     role: "HNCADM",
        //     token: 'aaa0ff9d524529b1d0d85d9c1fbfb296:67d5292de61bb276f941febc571db7fbe796db00d51de46d875928b3c0ca1584f834bc6ea277b713c4369093f010ec23'
        // }))
    }, [])

    return (
        <div>
            {
                isLoading ?
                    <h1>Loading . . . </h1>
                    :
                    <h1>{apiData?.total}</h1>
            }
            <img src='/images/bronze.png' alt="img" />
            <h1>{name}</h1>
            <h1>{user?.role}</h1>
            <button onClick={() => {
                navigate("/unauthorized");
            }}>next page</button>
        </div>
    )
}
