import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_URL } from "../config";

const useApiCaller = () => {
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => (state?.reducer?.user))

    const fetchData = async (method = "get", endpoint, body = {}) => {
         let BASE_URL = `${API_URL}hubdb/`;
        //  let BASE_URL = `http://192.168.0.111:6098/`;
        try {
            setIsLoading(true);
            const resp = await axios({
                method: method,
                url: BASE_URL + endpoint,
                headers: {
                    'authorization': user?.token,
                    'Content-Type': 'application/json',
                },
                data: body
            });
            const data = await resp?.data;
            setIsLoading(false);
            return data;
        } catch (error) {
            setIsLoading(false);
        }
    };
    const fetchDataFromUrl = async (method = "get", url, body = {}, sendToken = true) => {
        try {
            setIsLoading(true);
            let config = {
                method: method,
                url,
                data: body
            };
            if (sendToken) {
                config = {
                    ...config,
                    headers: {
                        'authorization': user?.token,
                        'Content-Type': 'application/json',
                    }
                }
            }
            const resp = await axios(config);
            const data = await resp?.data;
            setIsLoading(false);
            return data;
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    return { isLoading, fetchData, fetchDataFromUrl };
};

export default useApiCaller;