import { Routes, Route } from "react-router-dom"
import Dashboard from "./screens/Dashboard"
import { connect } from 'react-redux';
import Sidebar from "./components/Sidebar";
import Clients from "./screens/Clients";
import UnAuthorizes from "./screens/UnAuthorizes";
import Login from "./screens/Login";
import Authorization from "./helpers/Authorization";
import ClientDashboard from "./screens/ClientDashboard";
import HubDashboard from "./screens/HubDashboard";
import Profile from "./screens/Profile";
import ManageHoncAreaManagers from "./screens/ManageHoncAreaManagers";
import Stats from "./screens/Stats";
import PartnersList from "./components/PartnersList";
import VehiclesList from "./components/VehiclesList";
import AreaManager from "./screens/AreaManager";
// import { actions } from '../src/redux/actions';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<Sidebar />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route element={<Authorization allowedRoles={["HNC"]} />}>
            <Route path="/manage-honc-area-managers" element={<ManageHoncAreaManagers />} />
          </Route>
          <Route element={<Authorization allowedRoles={["HNC", "HNC-RGN"]} />}>
            <Route path="/clients" element={<Clients />} />
          </Route>
          <Route element={<Authorization allowedRoles={["HNC", "HNC-RGN","CLT","CLT-RGN","CLT-HUB"]} />}>
            <Route path="/Stats" element={<Stats/>} />
          </Route>
          <Route element={<Authorization allowedRoles={["HNC"]} />}>
            <Route path='/AreaManager' element={<AreaManager/>} />
          </Route>
          <Route element={<Authorization allowedRoles={["CLT-HUB"]} />}>
            <Route path="/PartnersList" element={<PartnersList/>} />
          </Route>
          <Route element={<Authorization allowedRoles={["CLT-HUB"]} />}>
            <Route path="/VehiclesList" element={<VehiclesList/>} />
          </Route>

          <Route element={<Authorization allowedRoles={["HNC", "HNC-RGN", "CLT", "CLT-RGN"]} />}>
            <Route path="/client-dashboard" element={<ClientDashboard />} />
          </Route>
          <Route element={<Authorization allowedRoles={["HNC", "HNC-RGN", "CLT", "CLT-RGN", "CLT-HUB"]} />}>
            <Route path="/hub-dashboard" element={<HubDashboard />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/unauthorized" element={<UnAuthorizes />} />
      </Routes>
    </div>
  )
}

// const mapStateToProps = state => ({
//   ...state
// })

// const mapDispatchToProps = dispatch => ({
//   simpleAction: () => dispatch(simpleAction())
// })
// mapStateToProps, mapDispatchToProps

export default connect()(App);

