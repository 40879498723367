import React, { Suspense, lazy, useEffect, useState } from 'react'
import PageBreadCrumb from '../components/PageBreadCrumb';
import useApiCaller from '../hooks/useApiCaller';
import AddonJobsList from '../components/AddonJobsList';
import moment from 'moment';
import { useSelector } from 'react-redux';

//importing components with lazy loading
const HubsList = lazy(() => import("../components/HubsList"));
const ManagersList = lazy(() => import("../components/ManagersList"));
const PartnersList = lazy(() => import("../components/PartnersList"));
const PackagesList = lazy(() => import("../components/PackagesList"));
const VehiclesList = lazy(() => import("../components/VehiclesList"));
const JobsList = lazy(() => import("../components/JobsList"));

export default function ClientDashboard() {
    const [clientDetails, setClientDetails] = useState({});
    const queryParams = new URLSearchParams(window.location.search);
    const clientId = queryParams.get('id');
    const { fetchData } = useApiCaller();
    const [activeTab, setActiveTab] = useState('Hubs');
    const user = useSelector(state => state?.reducer?.user);
    const [tabs,setTabs] = useState([
        {
            label: "Hubs",
            icon: "ti ti-building-store"
        },
        {
            label: "Managers",
            icon: "ti ti-users"
        },
        {
            label: "Partners",
            icon: "ti ti-user"
        },
        {
            label: "Vehicles",
            icon: "ti ti-car"
        },
        {
            label: "Jobs",
            icon: "ti ti-file-description"
        },
        // {
        //     label: "Vacuuming",
        //     icon: "ti ti-checklist"
        // }

    ])

    useEffect(() => {
        getClientDetails();
        if (user?.role == "HNC" || user?.role == "HNC-RGN") {
            let fTabs = tabs;
            fTabs.splice(2,0,{
                label: "Packages",
                icon: "ti ti-receipt"
            })
            setTabs(fTabs)
        }
    }, [])

    const getClientDetails = async () => {
        let clientDetails = await fetchData('get', `v1/client/get/${clientId}`);
        if (clientDetails?.success) {
            setClientDetails(clientDetails?.data);
        }
    }

    const renderSection = () => {
        switch (activeTab) {
            case "Hubs":
                return <HubsList
                    isFromClient={true}
                    clientId={clientId}
                />;
            case "Managers":
                return <ManagersList
                    isFromClient={true}
                    clientId={clientId}
                />;
            case "Partners":
                return <PartnersList
                    clientId={clientId}
                    isFromClient={true}
                />;
            case "Packages":
                return <PackagesList
                    isFromClient={true}
                    clientId={clientId}
                />;
            case "Vehicles":
                return <VehiclesList
                    isFromClient={true}
                    clientId={clientId}
                />;
            case "Jobs":
                return <JobsList
                    isFromClient={true}
                    clientId={clientId}
                />;
            case "Vacuuming":
                return <AddonJobsList
                    isFromClient={true}
                    clientId={clientId}
                />
            default:
                return null;
        }
    }

    return (
        <div className="container-fluid">
            <PageBreadCrumb
                pageTitle={clientDetails?.name}
                showButton={true}
                phonedetails={clientDetails?.phone}
                emaildetails={clientDetails?.email}
                address={clientDetails?.address}
                joiningDate={clientDetails?.createdAt ? moment(clientDetails?.createdAt).format('DD MMM, YYYY - LT') : ''}
                gstin={clientDetails?.gstin}
                logo={clientDetails?.logo}
                breadCrumbs={
                    user.role == "CLT-RGN" || user.role == "CLT" ?
                        [
                            {
                                label: 'Client Dashboard',
                                link: '#'
                            }
                        ]
                        :
                        [
                            {
                                label: 'Clients',
                                link: '/clients',
                            },
                            {
                                label: 'Client Dashboard',
                                link: '#'
                            }
                        ]}

            />



            <div className="card">
                <div className="card-body">
                    <div>
                        {/* Nav tabs */}
                        <ul className="nav nav-tabs mb-4" role="tablist">
                            {tabs?.map(item => (
                                <li className="nav-item" key={item?.label} style={{
                                    cursor: 'pointer'
                                }} >
                                    <a className={`nav-link d-flex ${activeTab == item?.label ? "active" : ""}`} onClick={() => {
                                        setActiveTab(item?.label)
                                    }} data-bs-toggle="tab" role="tab">
                                        <span><i className={`${item?.icon} fs-4`} />
                                        </span>
                                        <span className="d-none d-md-block ms-2">{item?.label}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" role="tabpanel">
                                <Suspense
                                    fallback={
                                        <div>
                                            <h4>Loading ... </h4>
                                        </div>
                                    }
                                >
                                    {renderSection()}
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
