import React, { useState } from 'react';
import "../../styles/Badges.css"
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import useApiCaller from '../../hooks/useApiCaller';
import { useSelector } from 'react-redux';
import ChangePassword from '../modals/ChangePassword';
export default function ManagerCard(props) {
    const { fetchData } = useApiCaller()
    const user = useSelector(state => state?.reducer?.user);

    const handleActivateOrDeactivate = () => {
        const action = props?.accountStatus === "Inactive" ? 'Activate' : 'Deactivate';

        Swal.fire({
            title: `${action} Account`,
            text: `Are you sure you want to ${action.toLowerCase()} this partner?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: action === 'Activate' ? '#12DEB9' : '#FA896B',
            cancelButtonColor: 'red',
            confirmButtonText: `Yes, ${action}!`,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const updatedStatus = action === 'Activate' ? 'Active' : 'Inactive';
                let reqBody = {
                    accountStatus: updatedStatus
                }
                let apiResult = await props?.updateManager(props?.managerId, reqBody)
                if (apiResult?.success) {
                    Swal.fire(
                        `Account ${action === 'Activate' ? 'Activated' : 'Deactivated'}`,
                        `Your account has been ${action === 'Activate' ? 'activated' : 'deactivated'}.`,
                        'success'
                    );
                } else {
                    Swal.fire(
                        'Error',
                        'An error occurred while updating the account status.',
                        'error'
                    );
                }
            }
        });
    };
console.log(ChangePassword)

    return (
        <div className="card w-100 pe-4">
            <div className={`position-absolute top-0 end-0 ${props?.accountStatus == "Inactive" ? "bg-danger" : "bg-success"}`} style={{
                height: '18px',
                width: '60px',
                textAlign: 'center'
            }}>
                {props?.accountStatus}

            </div>
            <div className='d-flex flex-row pt-4 ps-4 justify-content-between align-items-center'>
                <h4 className='mb-0 '>{props?.name}</h4>
                <div className="dropdown dropstart">
                    <a href="#" className="link text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="ti ti-dots fs-7" />
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        <li>
                            <p
                                className="dropdown-item"
                                style={{
                                    cursor: 'pointer',
                                    color: props?.accountStatus === "Inactive" ? 'green' : 'red', // Change color based on status
                                }}
                                onClick={handleActivateOrDeactivate}
                            >
                                {props?.accountStatus === "Active" ? "Deactivate" : "Activate"}
                            </p>
                        </li>
                        {props?.role != "CLT" && !props?.isFromHub ?
                            <li><p className="dropdown-item"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    props?.handleSelectManagerToUpdate(props?.managerId, 'manageHubs');
                                }}
                            >
                                Manage Hubs
                            </p>
                            </li>
                            :
                            null
                        }
                        <li><p className="dropdown-item"
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                props?.handleSelectManagerToUpdate(props?.managerId, 'update');
                            }} >
                            Update Details
                        </p>
                        </li>

                        <li><p className="dropdown-item"
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                props?.handleSelectManagerToUpdate(props?.managerId, 'changepassword');
                            }} >
                            Change Password
                        </p>
                        </li>
                        {/* <li><p className="dropdown-item"
                            style={{
                                cursor: 'pointer',
                                color: 'red'
                            }}>Delete</p></li> */}
                    </ul>
                </div>

            </div>

            <div className="p-4 pt-0 pb-2">
                <div>
                    <h6 className='mb-0 text-primary fw-bold mt-2'>{props?.roleFullForms[props?.role]} </h6>
                    <h6 className="mb-0 text-muted">
                        {props?.email}
                    </h6>
                    <h6 className="mb-0 text-muted">
                        {props?.phone}
                    </h6>
                    {user?.role == "HNC" ?
                        <h6 className="mb-0 text-muted mt-2">
                            <b>Password: </b><br />{props?.password}
                        </h6>
                        :
                        null
                    }

                </div>
            </div>
            <div className='ps-4 pe-4 pb-4'>
                {props?.hubs?.map(item => (
                    <button className="btn btn-outline-primary me-3 mt-2"
                        style={{
                            padding: '1px 12px',
                            borderRadius: '50px'
                        }}
                    >
                        {item?.name}
                    </button>
                ))}
            </div>
        </div >

    )
}
