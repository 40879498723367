export const roles = [
    {
        label: "Honc Area Manager",
        value: "HNC-RGN",
        priority: 1
    },
    {
        label: "Client Admin",
        value: "CLT",
        priority: 2
    },
    {
        label: "Client Area Manager",
        value: "CLT-RGN",
        priority: 3
    },
    {
        label: "Client Hub Manager",
        value: "CLT-HUB",
        priority: 4
    }
]
const filteredRoles = roles.filter(role => role.priority === 1 && role.value === "HNC-RGN");
console.log(filteredRoles);