import React from 'react'

const PartnerAadharImage = (props) => {
  return (
    <div> <div>
    {/* Button trigger modal */}
 
    {/* Modal */}
    <div className="modal fade" id="partnerAdharDetails" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header " style={{backgroundColor:"#49BEFF"}}  >
            <h5 className="modal-title text-white fw-semibold" id="exampleModalLabel">AadharImage</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{color:"white"}} />
          </div>
          <div className="modal-body">
            <div className='row d-flex w-100 text-center'>
           <div className='col-lg-12 '>
            <div className='row mt-2 justify-content-center align-items-center '>
                <p className='fw-bold fs-5 text-dark'>Aadhar-front-image</p>
            </div>
            <div className='row d-flex justify-content-center'>
           <img src={props?.aadhar_front_image } alt="aadhar_front_image" style={{width:"42%"}}></img>
           </div>
           </div>
          
           <div className='col-lg-12 '>
            <div className='row mt-4'>
                <p className='fw-bold fs-5 text-dark'>Aadhar-back-image</p>
            </div>
            <div className='row d-flex justify-content-center '>
            <img src={props?.aadhar_back_image} style={{width:"42%"}} alt="aadhar_back_image"></img>
            </div>
           </div>
           </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default PartnerAadharImage