import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useApiCaller from '../hooks/useApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/actions';
import swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { useLottie } from "lottie-react";
import Caranimation from "../Caranimation.json";


export default function Login() {
    const options = {
        animationData: Caranimation,
        loop: false
    };
    const [isLoading,setIsLoading] = useState(false);

    const { View } = useLottie(options);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { fetchData } = useApiCaller();
    const user = useSelector(state => state?.reducer?.user);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm();

    const navigateBasedOnRole = (user) => {
        if (user?.role == "HNC") {
            navigate('/clients');
        }
        else if (user?.role == "HNC-RGN") {
            navigate('/clients');
        }
        else if (user?.role == "CLT") {
            navigate(`/client-dashboard?id=${user?.clientIds[0]}`);
        }
        else if (user?.role == "CLT-RGN") {
            navigate(`/client-dashboard?id=${user?.clientIds[0]}`);
        }
        else if (user?.role == "CLT-HUB") {
            navigate(`/hub-dashboard?id=${user?.hubIds[0]}`);
        }
    }

    useEffect(() => {
        if (user?.role) {
            navigateBasedOnRole(user)
        }
    }, [])

    const handleLogin = async () => {
        setIsLoading(true);
        let reqData = {
            email: watch('email'),
            password: watch('password')
        }
        let loginData = await fetchData('POST', 'v1/user/login', reqData);
        setIsLoading(false);
        if (loginData?.success) {
            dispatch(setUser({ ...loginData?.data }));
            // loginData?.role
            navigateBasedOnRole(loginData?.data);
        }
        else {
            swal.fire(loginData?.message, '', 'warning');
        }
    }

    return (
        <div>
            <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
                <div className="position-relative overflow-hidden radial-gradient min-vh-100">
                    <div className="position-relative z-index-5">
                        <div className="row">
                            <div className="col-xl-7 col-xxl-8">
                                <a href="./index.html" className="text-nowrap logo-img d-block px-4 py-9 w-100">
                                    <img src="./images/honc_logo.png" width={140} alt="" />
                                    <p className='fs-6  fw-semibold mt-2'>fleet management portal</p>
                                </a>
                                <div className="d-none d-xl-flex align-items-center justify-content-center" >
                                    {/* <img src="../../dist/images/backgrounds/login-security.svg" alt="" className="img-fluid" width={500} /> style={{ height: 'calc(100vh - 120px)' }} */}
                                    {View}
                                </div>
                            </div>
                            <div className="col-xl-5 col-xxl-4">
                                <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                                    <div className="col-sm-8 col-md-6 col-xl-9">
                                        <h2 className="mb-3 fs-7 fw-bolder">Welcome to honc</h2>
                                        <p className=" mb-9">Admin Dashboard</p>
                                        <form onSubmit={handleSubmit(handleLogin)}>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                                                <input type="email" {...register("email", { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                {errors.email && <p><small className='text-danger'>Please enter a valid email.</small></p>}
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                                <input type="password" {...register("password", { required: true })} className="form-control" id="exampleInputPassword1" />
                                                {errors.password && <p><small className='text-danger'>Please enter a password.</small></p>}
                                            </div>
                                            <button type='submit' disabled={isLoading} className="btn btn-primary w-100 py-8 mb-4 rounded-2">Sign In</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
