import React from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';


export default function Profile() {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
      } = useForm();
      const user = useSelector(state => state?.reducer?.user);  
      useEffect(() => {
        setValue('name', user.name || '');
        setValue('phone', user.phone || '');
      }, [user.name, user.phone]);
     
   
    return (
        <div className="row">
   {/*          <div className="col-lg-6 d-flex align-items-stretch">
                <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body p-4">
                        <h5 className="card-title fw-semibold">Change Profile</h5>
                        <p className="card-subtitle mb-4">Change your profile picture from here</p>
                        <div className="text-center">
                            <img src="../../dist/images/profile/user-1.jpg" alt="" className="img-fluid rounded-circle" width={120} height={120} />
                            <div className="d-flex align-items-center justify-content-center my-4 gap-3">
                                <button className="btn btn-primary">Upload</button>
                                <button className="btn btn-outline-danger">Reset</button>
                            </div>
                            <p className="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 d-flex align-items-stretch">
                <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body p-4">
                        <h5 className="card-title fw-semibold">Change Password</h5>
                        <p className="card-subtitle mb-4">To change your password please confirm here</p>
                        <form>
                            <div className="mb-4">
                                <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Current Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" defaultValue={12345678910} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">New Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" defaultValue={12345678910} />
                            </div>
                            <div className>
                                <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Confirm Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" defaultValue={12345678910} />
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}





{user?.role == "HNC" || user?.role == "HNC-RGN" || user?.role == "CLT" ?
                     
            <div className="col-12">
                <div className="card w-100 position-relative overflow-hidden mb-0">
                    <div className="card-body p-4">
                        <h5 className="card-title fw-semibold">Personal Details</h5>
                        <p className="card-subtitle mb-4">To change your personal detail , edit and save from here</p>
                        <form>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Your Name</label>
                                        <input
                        type="text"
                        {...register('name', { required: true })}
                        className="form-control"
                        value={watch('name')}
                        placeholder="Enter name"
                      />                               </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Phone</label>
                                        <input
                        type="tel"
                        {...register('phone', {
                          pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/i,
                          required: true,
                          validate: (value) => {
                            if (!/^\d+$/.test(value)) {
                              return 'Please enter only digits for the phone number';
                            }
                            return true;
                          },
                        })}
                        maxLength="10"
                        className="form-control"
                        placeholder="Enter phone number"
                        value={watch('phone')}
                      />                   </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="d-flex align-items-center justify-content-end mt-4 gap-3">
                                        <button className="btn btn-primary">Save</button>
                                        <button className="btn btn-light-danger text-danger">Cancel</button>
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            :null
}
        </div>
    )
}
