import React from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import { setClientPageNum } from "../../redux/nonPersistActions";
import { useForm } from "react-hook-form";
import useApiCaller from "../../hooks/useApiCaller";
import { CUSTOMER_API_URL } from "../../config";
import useUploadImage from "../../hooks/useUploadImage";

export default function AddClient(props) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { fetchData } = useApiCaller();
  const { clientPageSize } = useSelector((state) => state?.nonPersistReducer);
  const dispatch = useDispatch();
  const { uploadImage } = useUploadImage();

  const handleUploadImage = async (e) => {
    let imageResponse = await uploadImage(e);
    if (imageResponse) {
      setValue("logo", imageResponse.link);
    }
  };

  const handleOnCreateClient = async (data) => {
    let createdResponse = await fetchData(
      "POST",
      "v1/client/create",
      JSON.stringify(data)
    );
    if (createdResponse?.success) {
      console.log("Client", createdResponse);
      document.getElementById("closeAddClientModalBtn").click();
      reset();
      props?.fetchAllClients(1, clientPageSize);
      dispatch(setClientPageNum(1));
      swal.fire(
        "Successfully Created",
        "A new client has been created successfully.",
        "success"
      );
    } else {
      swal.fire("Something went wrong", "", "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="addClientModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addContactModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center">
            <h5 className="modal-title">Create New Client</h5>
            <button
              type="button"
              id="closeAddClientModalBtn"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                <form
                  id="addContactModalTitle"
                  onSubmit={handleSubmit(handleOnCreateClient)}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3 contact-name">
                        <label>
                          Company Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("name", { required: true })}
                          className="form-control"
                          placeholder="enter company name"
                        />
                        {errors.name && (
                          <p>
                            <small className="text-danger">
                              Please enter company name.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 contact-email">
                        <label>
                          Phone<span className="text-danger">*</span>
                        </label>
                        <input
                          type="tel"
                          {...register("phone", {
                            required: true,
                            validate: (value) => {
                                if (!/^\d+$/.test(value)) {
                                    return 'Please enter only digits for the phone number';
                                }
                                if (!/^(?:(?:(?:\+|0{0,2})91\s*[-]?)?(?:\d{2}\s*[-]?)?)?[6789]\d{9}$/i.test(value)) {
                                    return 'Please enter a valid Indian phone number';
                                }
                                return true;
                            }
                          })}
                          maxLength="10"
                          className="form-control"
                          placeholder="enter phone number"
                        />
                           {errors.phone && (
                          <p>
                            <small className="text-danger">
                              {errors?.phone?.message}.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3 contact-phone">
                        <label>
                          Email<span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          {...register("email", {
                            pattern: "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$",
                            required: true,
                          })}
                          className="form-control"
                          placeholder="enter email"
                        />
                        {errors.email && (
                          <p>
                            <small className="text-danger">
                              Please enter a valid email.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 contact-location">
                        <label>GSTIN</label>
                        <input
                          type="text"
                          {...register("gstin", {
                            pattern:
                              /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/,
                          })}
                          className="form-control"
                          placeholder=" enter gstin"
                          maxlength="15"
                        />
                        {errors.gstin && (
                          <p>
                            <small className="text-danger">
                              Please enter a gstin.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 contact-phone">
                        <label>Address</label>
                        <textarea
                          {...register("address")}
                          rows="3"
                          className="form-control"
                          placeholder="enter address"
                        />
                        {errors.address && (
                          <p>
                            <small className="text-danger">
                              Please enter address.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 contact-phone">
                        <label>Logo</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => {
                            handleUploadImage(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-success rounded-pill px-4"
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}
