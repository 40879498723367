import { actionTypes } from "../../constants/nonPersistReduxConstants"

const initialState = {
    name: '',
    clientPageNum: 1,
    clientPageSize: 10,
    clientSearchText: '',
    vehiclePageNum: 1,
    vehiclePageSize: 10,
    vehicleSearchText: '',
    completedjobsPageNum: 1,
    completedjobsPageSize: 10,
    completedjobsSearchText: '',
    notattemptedjobsPageNum: 1,
    notattemptedjobsPageSize: 10,
    notattemptedjobsSearchText: '',
    approvalpendingjobsPageNum: 1,
    approvalpendingjobsPageSize: 10,
    approvalpendingjobsSearchText: '',
    incompletejobsPageNum: 1,
    incompletejobsPageSize: 10,
    incompletejobsSearchText: '',
    addoncompletedJobsPageNum: 1,
    addoncompletedJobsPageSize: 10,
    addoncompletedJobsSearchText: '',
    addonincompletedJobsPageNum: 1,
    addonincompletedJobsPageSize: 10,
    addonincompletedJobsSearchText: '',
    addonnotattemptedJobsPageNum: 1,
    addonnotattemptedJobsPageSize: 10,
    addonnotattemptedJobsSearchText: '',
    addonapprovalpendingJobsPageNum: 1,
    addonapprovalpendingPageSize: 10,
    addonapprovalpendingJobsSearchText: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIMPLE_ACTION:
            return {
                ...state,
                name: action.payload,
            }
        case actionTypes.SET_CLIENT_PAGE_NUM:
            return {
                ...state,
                clientPageNum: action.pageNum,
            }
        case actionTypes.SET_CLIENT_PAGE_SIZE:
            return {
                ...state,
                clientPageSize: action.pageSize,
            }
        case actionTypes.SET_CLIENT_SEARCH_TEXT:
            return {
                ...state,
                clientSearchText: action.searchText,
            }
        case actionTypes.SET_VEHICLE_PAGE_NUM:
            return {
                ...state,
                vehiclePageNum: action.pageNum,
            }
        case actionTypes.SET_VEHICLE_PAGE_SIZE:
            return {
                ...state,
                vehiclePageSize: action.pageSize,
            }
        case actionTypes.SET_VEHICLE_SEARCH_TEXT:
            return {
                ...state,
                vehicleSearchText: action.searchText,
            }

        case actionTypes. SET_COMPLETEDJOBS_PAGE_NUM:
            return {
                ...state,
                completedjobsPageNum: action.pageNum,
            }
        case actionTypes.SET_COMPLETEDJOBS_PAGE_SIZE:
            return {
                ...state,
                completedjobsPageSize: action.pageSize,
            }
        case actionTypes.SET_COMPLETEDJOBS_SEARCH_TEXT:
            return {
                ...state,
                completedjobsSearchText: action.searchText,
            }

            case actionTypes. SET_NOT_ATTEMPTEDJOBS_PAGE_NUM:
                return {
                    ...state,
                    notattemptedjobsPageNum: action.pageNum,
                }
            case actionTypes. SET_NOT_ATTEMPTEDJOBS_PAGE_SIZE:
                return {
                    ...state,
                    notattemptedjobsPageSize: action.pageSize,
                }
            case actionTypes.SET_NOT_ATTEMPTEDJOBS_PAGE_TEXT:
                return {
                    ...state,
                    notattemptedjobsSearchText: action.searchText,
                }

                case actionTypes. SET_INCOMPLETEJOBS_PAGE_NUM:
                    return {
                        ...state,
                        incompletejobsPageNum: action.pageNum,
                    }
                case actionTypes.SET_INCOMPLETEJOBS_PAGE_SIZE:
                    return {
                        ...state,
                        incompletejobsPageSize: action.pageSize,
                    }
                case actionTypes.SET_INCOMPLETEJOBS_PAGE_TEXT:
                    return {
                        ...state,
                        incompletejobsSearchText: action.searchText,
                    }



                    case actionTypes.SET_APPROVALPENDING_PAGE_NUM:
                        return {
                            ...state,
                            approvalpendingJobsPageNum: action.pageNum,
                        }
                    case actionTypes. SET_APPROVALPENDING_PAGE_SIZE:
                        return {
                            ...state,
                            approvalpendingjobsPageSize: action.pageSize,
                        }
                    case actionTypes.SET_APPROVALPENDING_PAGE_TEXT:
                        return {
                            ...state,
                            approvalpendingjobsSearchText: action.searchText,
                        }
        
    
    

        case actionTypes. SET_ADDON_COMPLETEDJOBS_PAGE_NUM:
            return {
                ...state,
                addoncompletedJobsPageNum: action.pageNum,
            }
        case actionTypes. SET_ADDON_COMPLETEDJOBS_PAGE_SIZE:
            return {
                ...state,
                addoncompletedJobsPageSize: action.pageSize,
            }
        case actionTypes.SET_ADDON_COMPLETEDJOBS_SEARCH_TEXT:
            return {
                ...state,
                addoncompletedJobsSearchText: action.searchText,
            }
            case actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_NUM:
                return {
                    ...state,
                    addonnotattemptedJobsPageNum: action.pageNum,
                }
            case actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_PAGE_SIZE:
                return {
                    ...state,
                    addonnotattemptedJobsPageSize: action.pageSize,
                }
            case actionTypes.SET_ADDON_NOT_ATTEMPTEDJOBS_SEARCH_TEXT:
                return {
                    ...state,
                    addonnotattemptedJobsSearchText: action.searchText,
                }
            
        case actionTypes.  SET_ADDON_INCOMPLETEDJOBS_PAGE_NUM:
            return {
                ...state,
                addonincompletedJobsPageNum: action.pageNum,
            }
        case actionTypes. SET_ADDON_INCOMPLETEDJOBS_PAGE_SIZE:
            return {
                ...state,
                addonincompletedJobsPageSize: action.pageSize,
            }
        case actionTypes. SET_ADDON_INCOMPLETEDJOBS_SEARCH_TEXT:
            return {
                ...state,
                addonincompletedJobsSearchText: action.searchText,
            }



        

 case actionTypes.SET_ADDON_APPROVALPENDING_PAGE_NUM:
            return {
                ...state,
                addonapprovalpendingJobsPageNum: action.pageNum,
            }
        case actionTypes.   SET_ADDON_APPROVALPENDING_PAGE_SIZE:
            return {
                ...state,
                addonapprovalpendingPageSize: action.pageSize,
            }
        case actionTypes.  SET_ADDON_APPROVALPENDING_SEARCH_TEXT:
            return {
                ...state,
                addonapprovalpendingJobsSearchText: action.searchText,
            }


        case actionTypes.RESET:
            return {
                ...initialState
            }
        default:
            return state
    }
}